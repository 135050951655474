const initialState = {
    userData: null,
    restaurant: null
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
    
        case "onLogin":
            return {
                ...initialState,
                userData: action.payload,
                roleid:action.payload.roleid
            };
     
        case "LOGOUT":
            return {
                ...initialState,
               userData: null
            };
        default:
            return state;
    }
}
