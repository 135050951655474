import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}

export function generateImage(text, fontSize = 30, fontFamily = 'Arial') {
  const initials = (text?.split(' ').map(word => word.charAt(0).toUpperCase()).join(''))?.slice(0, 2);
  const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
          <!-- Background rectangle -->
          <rect width="100%" height="100%" fill="#6c757d"/>
          
          <!-- Text element -->
          <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle"
              font-size="${fontSize}" font-family="${fontFamily}" fill="white">
              ${initials}
          </text>
      </svg>
  `;

  // Encode SVG string to data URL
  const encodedData = window.btoa(unescape(encodeURIComponent(svgString)));
  const dataURL = `data:image/svg+xml;base64,${encodedData}`;
  
  return dataURL;
}