import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import apiService from "../core/apiService";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
export const ProtectedRoute = ({ children }) => {
  const [cookies] = useCookies(['userId']);
  const userId = cookies?.userId;

  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userData && userId) {
      setLoading(true);
      apiService.get('/users/' + userId, {}, ({ err, data }) => {
        if (err) {
          console.error(err);
          setLoading(false);
        } else {
          dispatch({
            type: 'onLogin',
            payload: data.data
          });
          setLoading(false);
        }
      })
    }
  }, []);

  if (!userId) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return loading ? <></> : children;
};