import { toast } from "react-toastify";
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const getHeaders = (isFormData) => {
    const headers = new Headers();
    if (cookies.get('userToken')) {
        headers.append('Authorization', 'Bearer ' + cookies.get('userToken'));
        headers.append("Access-Control-Allow-Origin", "*");
    }
    if (!isFormData) {
        headers.append('Content-Type', 'application/json');
       
    }
    return headers;
};

const baseUrl = process.env.REACT_APP_API_URL;

const get = (endpoint, queryString = {}, callback) => {
    let fetchData = {
        method: "GET",
        headers: getHeaders()
    };
    let url = baseUrl + endpoint;
    if (Object.keys(queryString).length) {
        let queryParams = new URLSearchParams(queryString);
        url += `?${queryParams}`;
    }
    fetch(url, fetchData)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                callback({ data })
            } else {
                callback({ err: data })
            }
        })
        .catch(err => console.error(err));
}

const post = (endpoint, body, callback, args) => {
    let fetchData = {
        method: "POST",
        headers: getHeaders(args?.isFormData),
        body: JSON.stringify(body)
    };
    let url = baseUrl + endpoint;

    fetch(url, fetchData)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                callback({ data });
                toast.success(data.message || 'Saved Successfully', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "light",
                });
            } else {
                callback({ err: data })
                toast.error(data.error, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    theme: "light",
                });
            }
        })
        .catch(err => {
            console.error(err);
            toast.error('Server error', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "light",
            });
        });
}
const update = (endpoint, body, callback) => {
    let fetchData = {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body)
    };
    let url = baseUrl + endpoint;
    fetch(url, fetchData)
        .then(response => response.json())
        .then(data => callback({ data }))
        .catch(err => console.error(err));
}

const remove = (endpoint, callback) => {
    let fetchData = {
        method: "DELETE",
        headers: getHeaders()
    };
    let url = baseUrl + endpoint;

    fetch(url, fetchData)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
             callback({ data })
                toast.success(data.message || 'Deleted Successfully', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "light",
                });
            } else {
               callback({ err: data })
                toast.error(data.error, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    theme: "light",
                });
            }
        })
        .catch(err => {
            console.error(err);
            toast.error('Server error', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "light",
            });
        });
};

const SubscriptionPost = (endpoint, body, callback, args) => {
    let fetchData = {
        method: "POST",
        headers: getHeaders(args?.isFormData),
        body: JSON.stringify(body)
    };

    let url = baseUrl + endpoint;

    fetch(url, fetchData)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                callback({ data })
            } else {
                callback({ err: data })
            }
        })
        .catch(err => {
            console.error(err);
        });
}


export default {
    get,
    post,
    update,
    remove,
    SubscriptionPost
}