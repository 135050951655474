import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";



const BusinessVerify = () => {
    const navigate = useNavigate();
const location = useLocation();
const fromPage = location.state?.from?.pathname || '/membership';

const handleSubmit = (event) => {
    event.preventDefault();
    navigate(fromPage, {replace: true})
}
    
    return (
        <div>
            <div id="login-page">
		<div className="login-form">
			<div className="login-form-inner">
				<div className="login-logo">
					<img src="/assets/images/logo.png" alt="Whatson"/>
					<p>Business Verification</p>
				</div>
				<div className="login-form-box">
					<form onSubmit={handleSubmit}>
						<div className="mb-4">
							<label>Enter Your Business</label>
							<div className="input-icon">
								<span className='input-icon-alt'><FontAwesomeIcon icon={faMapMarkerAlt} /></span>
								<input type="text" id="username" className="form-control"/>
								<img alt="" src="assets/images/powered-by-google.png"/>
							</div>
						</div>
						<div className="mb-4 forgot-password">
							<label className="custom-check check-without-input"> If you are not registered with Google My Business Please <Link to="/alternate-verify-business"><b> Click here!</b></Link> 
							</label>
						</div>
						<div className="mb-3">
							<button className="btn btn-dark full-btn btn-lg signin-btn">Next</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div className="login-img">
			<div className="login-img-inner">
				<div className="dashboard-title">
					<h3>WELCOME TO</h3>
					<h1>Restaurant Panel</h1>
					<p>Register to Access Dashboard</p>
				</div>
			</div>
		</div>
	</div>
        </div>
    );
};

export default BusinessVerify;
