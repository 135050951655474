import React, { useState } from 'react';
import { Outlet } from "react-router-dom";
import { LeftNav } from '../components/leftNav'
import { TopNav } from '../components/TopNav'
import '../assets/css/components.css';
import '../assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';

const Layout = ({refresh}) => {
  const subscribe = useSelector(state => state.subscribe);
  const [toggle, setToggle] = useState(true);

  const handleClick = () => {
    setToggle(!toggle);
  };

    return (
        <>
       <TopNav refresh={refresh} toggle={toggle} handleClick={handleClick}  />
       <LeftNav toggle={toggle} handleClick={handleClick} />
        <div className="dashboard-inner" id="main">
        <Outlet/>
        </div>
      
            {/*<footer>2022</footer>*/}
      </>
    );
};

export {Layout};