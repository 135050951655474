import React from 'react';

import apiService from '../../core/apiService';
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';

const PrfileSetting = () => {
	const [password, setPassword] = useState('')
	const [newPassword, setnewPassword] = useState('')
	const [confirmPassword, setconfirmPassword] = useState('')
	const [isRevealPassword, setIsRevealPassword] = useState(false);
	const [isRevealnewPassword, setIsRevealnewPassword] = useState(false);
	const [isRevealconfirmPassword, setIsRevealconfirmPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate()

	const togglePassword = () => {
		setIsRevealPassword((prevState) => !prevState);
	}
	const togglenewPassword = () => {
		setIsRevealnewPassword((prevState) => !prevState);
	}
	const toggleConfirmPassword = () => {
		setIsRevealconfirmPassword((prevState) => !prevState);
	}


	const handlePasswordChange = (prop) => (event) => {
		setPassword({ ...password, [prop]: event.target.value });
	};
	const [formError, setFormError] = useState({
		password: "",
		newPassword: "",
		confirmPassword: "",
	});

	const validateFormInput = (event) => {
		event.preventDefault();
		let inputError = {
			password: "",
			newPassword: "",
			confirmPassword: ""
		};
		if (!password.password) {
			setFormError({
				...inputError,
				password: "Password should not be empty",
			});
			return
		}
		if (!newPassword) {
			setFormError({
				...inputError,
				newPassword: "newPassword should not be empty",
			});
			return
		}



		if (newPassword !== confirmPassword) {
			setFormError({
				...inputError,
				confirmPassword: "Password and confirm password should be same",
			});
			return;
		}
		event.preventDefault();
		console.log(newPassword, "newPassword")
		console.log(confirmPassword, "confirmPassword")
		setLoading(true);
		apiService.post("/editPassword", { oldPassword: password.password, newPassword }, ({ err, data }) => {

			console.log(err, data)
			if (err) {
				setLoading(false);
				console.log(err.err);
				return;
			};
			setLoading(false);
		})

	}


	const handleOnFocus = (e) => {
		setFormError({ ...formError, [e.target.name]: '' })
	}

	const handleBlur = (e) => {
		setFormError({ ...formError, [e.target.name]: '' })
	}




	return (
		<>
			<div className="dashboard-auto-wrapper">
			<div className='back-redirect'>
                <button  className="btn btn-default bg-dark filter-btn text-light" onClick={()=>navigate(-1)}>Back</button>
                </div>
				<div className="row">
					<div className="col-lg-2"></div>
					<div className="col-lg-8">
						<div className="d-flex justify-content-between align-items-center">
							<div className="profile-img-box">


							</div>
						</div>
						<div className="form-sec margin-t30">
							<div className="row">
								<form onSubmit={validateFormInput}>
									<div className="col-sm-6">


									</div>
									<div className="col-sm-12">


										<label className="form-label">Old Password</label>

										<span className='form-control d-flex' >
											<input className='w-100 password-white'
												onChange={handlePasswordChange("password")}
												type={isRevealPassword ? 'text' : 'password'}
												name='password' onFocus={handleOnFocus}
											/>

											<span className='password-key' onClick={togglePassword} >
												{isRevealPassword ?
													<FontAwesomeIcon icon={faEye} />
													:
													<FontAwesomeIcon icon={faEyeSlash} />
												}
											</span>

										</span>
										<span className="error-message text-danger">
											{formError.password}</span>
									</div>
									<div className="col-sm-12">
										<div className="mb-4">
											<label className="form-label"> New Password</label>
											<span className='form-control d-flex' >
												<input className='w-100 password-white'
													name='newPassword'
													type={isRevealnewPassword ? 'text' : 'password'}
													onChange={(e) => setnewPassword(e.target.value)} onFocus={handleOnFocus} />
												<span className='password-key' onClick={togglenewPassword} >
													{isRevealnewPassword ?
														<FontAwesomeIcon icon={faEye} />
														:
														<FontAwesomeIcon icon={faEyeSlash} />
													}
												</span>

											</span>
											<span className="error-message text-danger" >{formError.newPassword}</span>
										</div>

									</div>
									<div className="col-sm-12">
										<div className="mb-4">
											<label className="form-label">Confirm Password</label>


											<span className='form-control d-flex' >
												<input className='w-100 password-white'
													name='confirmPassword'
													type={isRevealconfirmPassword ? 'text' : 'password'}
													onChange={(e) => setconfirmPassword(e.target.value)} onFocus={handleOnFocus} />
												<span  className='password-key'  onClick={toggleConfirmPassword} >
													{isRevealconfirmPassword ?
														<FontAwesomeIcon icon={faEye} />
														:
														<FontAwesomeIcon icon={faEyeSlash} />
													}
												</span>

											</span>
											<span className="error-message text-danger">{formError.confirmPassword}</span>
										</div>
									</div>
									<div className="col-sm-12">
										<div className="create-from-btn text-center">
											<button className="btn btn-dark edit-profile-btn">{
													loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update Password'
												}
												<span className='edit-btn-icon' type='submit'></span></button>
										</div>

									</div>
								</form>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrfileSetting;