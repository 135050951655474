import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import apiService from '../../core/apiService';
import { Spinner } from 'react-bootstrap';





const AdminMembership = () => {
	
	const [isFilter, setisFilter] = useState(false);
	const [listmemb,setListMem]=useState([])
	const [listcount,setCount]=useState([])
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const navigate = useNavigate()
	useEffect(()=>{
		apiService.get("/listmembers",{},({err,data}) =>{
			console.log(data.data,"datahhjjhf")
			if (err){
				console.log(err.err);
				return;
			};
			setListMem(data.data.subcription)
			setCount(data.data.restaurantsubscription)
			   }	
		)
},[])
	console.log(listcount , "chekjkdjskwsjs")

	return (
		<>
			<div className="dashboard-top-btn">
			<div className='back-redirect'>
                <button  className="btn btn-default bg-dark filter-btn text-light" onClick={()=>navigate(-1)}>Back</button>
                </div>
			</div>
			<div className="table-sec common-table margin-t30">
				<div className="table-responsive">
					<table className="table white-td-table">
						<thead>
							<tr>
								<th>Membership Name</th>
								<th>Type</th>
								<th>Normal Price</th>
								<th >Total Members</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{listmemb.length?<>
							{listmemb?.map(item=><tr key={item.id}>
								<td>{item.subscription_name}</td>
								<td>{item.subscription_type}</td>
								<td >${item.subscription_price}</td>	
												
								<td class="table-number" >{listcount.filter(ite=>ite.susbcription_id == item.id)[0]?.count || 0}</td>
								<td className="table-action button-fix">
									<Link to="/admin-membership/update" state={{ type:item.subscription_type, features:item,}}><button className="btn btn-success">Update</button></Link>
								</td>
							</tr>)}
							</>:
							<tr><td colSpan="6" className="text-center">
							<Spinner/>
						     </td></tr> 
							}
						</tbody>
					</table>
				</div>
			</div>
			
		</>
	);
};

export default AdminMembership;