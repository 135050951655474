import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router';

const DailySpecial = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	
	const [show, setShow] = useState(false);
	const navigate = useNavigate()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return (
        <>		
		<div className="dashboard-top-btn">
		<div className='back-redirect'>
                <button  className="btn btn-default bg-dark filter-btn text-light" onClick={()=>navigate(-1)}>Back</button>
                </div>
			<div className="table-filter">
				<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="../../assets/images/filter-ico.svg"/>Filters</button>
			</div>
			<button className="btn btn-dark" onClick={handleShow}><FontAwesomeIcon icon={faPencilAlt} /> Create</button>
		</div>
		<div className="table-sec common-table margin-t30">
					<div className="table-responsive">
						<table class="table white-td-table">
							<thead>
								<tr>
									<th>Title</th>
									<th>Message</th>
									<th>Date & Time</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Happy Hours</td>
									<td>Up to 25% Off on Happy Lamb Hot Pot</td>
									<td>07 Nov, 2022    11:54 AM</td>
								</tr>
								<tr>
									<td>Happy Hours</td>
									<td>Up to 25% Off on Happy Lamb Hot Pot</td>
									<td>07 Nov, 2022    11:54 AM</td>
								</tr>							
							</tbody>
						</table>
					</div>
				</div>
		{isFilter === true ?
		<>
		<div className="popup-overlay show"></div>
		<div id="filter-pop" className='show'>
			<div className="filter-pop-inner">
				<div className="filter-head">
					<h4>Filters</h4>
					<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
				</div>
					<div className="filter-body">
						<div className="mb-4">
							<div className="input-icon">
								<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
								<input type="text" className="form-control" placeholder="Type here..."/>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-md-6">
								<div className="input-icon">
									<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
									<input type="text" className="form-control" placeholder="Start Date"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="input-icon">
									<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
									<input type="text" className="form-control" placeholder="End Date"/>
								</div>
							</div>
						</div>
					</div>
					<div className="filter-form-btns">
						<button className="btn btn-dark full-width-btn add-couple" onClick={showFilter}>Apply Filter</button>
					</div>
			</div>
		</div>
		</> : <>
		<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
				<button type="button" class="btn-close" onClick={handleClose}></button>
				<div class="custom-pop-inner">
					<div class="custom-pop-box">
						<div id="add-user-form">
						<div class="custom-pop-body">
							<h4 class="pop-title text-center">Send Notification to your Followers</h4>
							<div class="pop-form">
								<div class="mb-3">
									<label>Title</label>
									<input type="text" id="name" class="form-control"/>
									<span class="error name-error">This field is required*</span>
								</div>
								<div class="mb-5">
									<label>Message</label>
									<textarea id="email" class="form-control"></textarea>
									<div class="msgarea-msg">100 Character(s) Left</div>
									<span class="error email-error">This field is required*</span>
								</div>
							</div>
						</div>
						<div class="custom-pop-footer">
							<div class="text-center">
								<button type="submit" onClick={handleClose} class="btn btn-dark btn-long">Send</button>
							</div>
						</div>
					</div>
					</div>
				</div>
		</Modal>
		</>
}
        </>
    );
};

export default DailySpecial;