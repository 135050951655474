import React, {useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes, faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Filter from '../../assets/images/filter-ico.svg';
import apiService from '../../core/apiService';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { REACT_APP_LIMIT } from '../../apiServices/apiServices';
import _ from 'lodash';
const MasterEntryGames = () => {
	const [isFilter, setisFilter] = useState(false);
	const [restaurantdata , setRestaurantData]=useState(null)
	const [value , setValue]= useState('')

	const [data,setData]=useState([])
	const navigate = useNavigate()
	const [id ,setId] = useState('') 
	const [refresh, setRefresh] = useState(false)
	const [show, setShow] = useState(false);
	const [inputValue , setInputValue] = useState('')
	const [restaurant, setRestaurant] = useState(null)
	const [appliedFilters, setAppliedFilters] = useState({
		search: '',
	})
	const [total, setTotal] = useState([])
	const [currentPage, setCurrentPage] = useState(1)


	console.log(restaurantdata, "checking the restraunt data ")
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
		if (isFilter === true) return
		setCurrentPage(1)

	};


	const updateData = (page) => {
		setCurrentPage(page)
	}
	const handleSearch=(args)=>{
		let params = {
			pageno: currentPage,
			search: appliedFilters.search,
			// start_date: appliedFilters.start_date,
			// end_date: appliedFilters.end_date
		}
		if (args?.filters) {
			params = { ...params, ...args.filters };
		}
		setRestaurantData(null)
		apiService.get('/getAllSport',params, ({ err, data }) => {
			if (err) return console.log(err);
			setRestaurantData(data.data.rows);
			setRestaurant(data.data.rows)
			setTotal(data.data.count)
			setisFilter(false)
		});
	}
	const resetFilters = () => {
		setAppliedFilters({
			search: ''
			// start_date: '',
			// end_date: '',
		});
		handleSearch({
			filters: {
			  search: ''
			//   start_date: '',
			//   end_date: '',
			},
		  });
	}

	useEffect(() => {
		handleSearch({
			filters: appliedFilters
		})
	}, [refresh,currentPage])

	const lastPage = Math.ceil(total / REACT_APP_LIMIT)
	console.log(total,"total")
	console.log(lastPage, "lastpage")
	const nextPage = () => {
		if (currentPage < lastPage) {
			updateData(currentPage + 1)
		}
	}
	const previousPage = () => {
		updateData(currentPage - 1)
	}


	const handleUpdate  =(id, value)=>{
	let already=restaurant?.filter((item => item.sports_name.toLowerCase() === value.toLowerCase()))
	if(!value) toast.error("The input field cannot be left blank.", {
			position: "top-right",
			autoClose: 1500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: false,
			theme: "light",
		});
		if(already.length >0 ){
			setRestaurantData(restaurant)
			toast.error("Already Exist", {
				position: "top-right",
				autoClose: 1500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				theme: "light",
			});
		}
		if (value && already.length == 0) {	apiService.post(`/updateSports/${id}`, {sports_name:value}, ({ err, data }) => {
			if (err) return console.log(err);
			setRefresh(!refresh)
		});
	}
	}
	const handleDelete  =()=>{
		apiService.remove(`/deleteSport/${id}`, ({ err, data }) => {
			if (err) {
				console.error(err);
			} else {
				// Assuming setRefresh is a state update function
				setRefresh(!refresh);
				handleClose()
			}
		});
	}
	const handleCreate=(inputValue)=>{
	if(!inputValue) toast.error("The input field cannot be left blank.", {
			position: "top-right",
			autoClose: 1500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: false,
			theme: "light",
		});
		else apiService.post('/addSport', {"sports_name":inputValue}, ({ err, data }) => {
			if (err) return console.log(err);
			setInputValue('')
			setRefresh(!refresh)
		});
	}
	const updateState = (data,id,value) => {
		const newState = data.map(obj => {
		  // 👇️ if id equals 2, update country property
		  if (obj.id === id) {
			return {...obj, sports_name: value};
		  }
		  // 👇️ otherwise return the object as is
		  return obj;
		});
		setRestaurantData(newState);
	  };

	  const debouncedHandleSearch = _.debounce((value) => {
		handleSearch({
			filters: {
				search: value,
			},
		});
	}, 300); 

	const handleChange = (e) => {
		const value = e.target.value;
		setInputValue(value);
		debouncedHandleSearch(value);


	};

	useEffect(()=>{
		setData(restaurantdata?.filter((item => item.sports_name.toLowerCase() === inputValue.toLowerCase())))
	},[restaurantdata])


	  console.log(restaurantdata , "checking the restraunt data ")

	  const handleClose = () => {setShow(false);setId('')}
	  const handleShow =(id)=> { setShow(true);setId(id) };

	return (
		<>
			<div className="dashboard-top-btn">
			<div className='back-redirect'>
                <button  className="btn btn-default bg-dark filter-btn text-light" onClick={()=>navigate(-1)}>Back</button>
                </div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
				</div>
			</div>
			<div className="table-sec common-table margin-t30 ">
			{/* table-sec common-table margin-t30 */}
				<div className="table-responsive">
					<div className="table-sec common-table margin-t30 Beer-table ">
						<div className="table-responsive">
							<table className="table white-td-table">
								<thead>
								<tr>
										<th>Sports Type</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr className="food-categories">
										<td><input type="name" className="form-control" value={inputValue} placeholder="eg. MLB"onChange={handleChange} /></td>
										<td className="table-action">
											<button className="btn btn-success add "disabled={!!(data?.length)}  onClick={()=>handleCreate(inputValue)}>Add</button>
											{!!data?.length? <span style={{text:"white"}}>Already exist
											</span> : <span></span>}
										</td>
									</tr>
									<>
									{
										restaurantdata == null ?<div className='text-center'>  <Spinner/> </div>:
										<>
											{restaurantdata.length?<>
										{restaurantdata?.map(item=><tr>
										<td >
										<input value={item.sports_name} className="form-control" onChange={(e)=>updateState(restaurantdata,item.id,e.target.value)} /> 
											</td>
										<td className="table-action">
											<button className="btn btn-success" onClick={()=>handleUpdate(item.id,item.sports_name)}>Update</button>
											<button className="btn btn-danger"  onClick={()=>handleShow(item.id,setValue(item.sports_name))}>Delete</button>
										</td>
									</tr>)}
									</>:
								<tr><td colSpan="6" className="text-center">
								No Data Found
							</td></tr> }
										</>
									}
								
									</>
									
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input
											value={appliedFilters.search}
											onChange={(e) => setAppliedFilters({ ...appliedFilters, search: e.target.value })}
											type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark full-width-btn add-couple"   onClick={() => handleSearch({
									filters: appliedFilters
								})}>Apply Filter</button>
								<button className="btn btn-dark full-width-btn add-couple mt-3" onClick={resetFilters}>
									Reset Filters
								</button>
							</div>
						</div>
					</div>
				</> : <>
				<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<div className="custom-pop-body">
										<h4 className="pop-title text-center">Are You Sure you want to Delete {value} ?</h4>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={handleClose} className="btn btn-secondary mx-2">No</button>
											<button type="submit" onClick={handleDelete} className="btn btn-danger">Yes</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
							{
							lastPage > 1? 
							<>
				
							{ <ul className="pagination justify-content-center">
										<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
											<button className="page-link" onClick={previousPage}>
												Previous
											</button>
										</li>
										{Array.from({ length: lastPage  }).map((_, index) => (
											<li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
												<button className="page-link" onClick={() => updateData(index + 1)}>
													{index + 1}
												</button>
											</li>
										))}
										<li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
											<button className="page-link" onClick={nextPage}>
												Next
											</button>
										</li>
									</ul>
									}
									</>:<></>
					}
				
				</>
			}
		</>
	);
};

export default MasterEntryGames;
