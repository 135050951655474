import React,{useState} from 'react';
import { useLocation, useNavigate} from 'react-router-dom';

const Membership = () => {    
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/dashboard';
	const [isPayment, setisPayment] = useState(false);
	const planPremium = ["plan-box"];
	const planBasic = ["plan-box"];
	const handleSubmit = () => {
		setisPayment(isPayment === false ? true : false);
		setTimeout(() => {
			navigate(fromPage, {replace: true})
		}, 2000);		
	}
	const [plan, setPlan] = useState('');
	const [isDisabled, setDisabled] = useState(false);

	const changePlanPreminum = () => {
		setPlan('premium');
		planBasic.push('');
		setDisabled(true);
	}
	const changePlanBasic = () => {
		setPlan('basic');
		planBasic.push('selected');
		planPremium.push('');
		setDisabled(true);
	}
	if(plan === 'basic') {
		planBasic.push('selected');
		planPremium.push('');
	}
	if(plan === 'premium') {
		planBasic.push('');
		planPremium.push('selected');
	}

/*
	const togglePlanPremium = () => {
		setPlanPremium(isPlanPremium === false ? true : false);
		setDisabled(isDisabled === false ? true : false);
	  };
	if(isPlanPremium) {
        planPremium.push('selected');
    }else {
        planPremium.push('');
    }
	
	const togglePlanBasic = () => {
		setPlanBasic(isPlanBasic === false ? true : false);
		setDisabled(isDisabled === false ? true : false);
	  };
	  if(isPlanBasic) {
        planBasic.push('selected');
		}else {
			planBasic.push('');
		}*/

    return (
        <div>
			<div id="verification-page">
            	<div className="container">		
				<div className="verification-logo">
				<img  alt="" src="assets/images/logo.png"/>
				<p>Select Your Membership</p>
			</div>
			<div className="row">
				<div className="col-md-2"></div>
				<div className="col-md-4">					
					<div className={planBasic.join(' ')}>
						<h4>Basic Membership</h4>
						<div className="plan-price">
							<span className="price-con">$0.0</span>
							<span>/month</span>
						</div>
						<ul className="plan-point">
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
						</ul>
						<button className="btn gray-transparent-btn choose-plan-btn" onClick={changePlanBasic}>Choose Plan</button>
					</div>
				</div>
				<div className="col-md-4">					
					<div className={planPremium.join(' ')}>
						<h4>Premium Membership</h4>
						<div className="plan-price">
							<span className="price-con">$39.99</span>
							<span>/month</span>
						</div>
						<ul className="plan-point">
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
						</ul>
						<button className="btn gray-transparent-btn choose-plan-btn" onClick={changePlanPreminum}>Choose Plan</button>
					</div>
				</div>
			</div>
			<div className="continue-btn">
				<button onClick={handleSubmit} className="btn btn-dark payment-btn" disabled={!isDisabled}>Continue to Pay</button>
			</div>
				</div>
			</div>
			{isPayment === true ?
			<>
			<div className="common-message-pop show" id="payment-success-pop">
				<div className="common-pop-inner">
					<div className="common-pop-box">
						<div className="popup-logo">
							<img alt="" src="assets/images/logo.png"/>
						</div>
						<div className="message-icon">
							<img alt="" src="assets/images/sucess-tick.png"/>
						</div>
						<p className="message-txt">Payment Successful!!</p>
					</div>
				</div>
			</div>
			</> : <></>
			}
        </div>
    );
};

export default Membership;
