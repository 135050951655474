import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';


const DailyDrinkCreate = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/happy-hour/drink';
	
	const handleSubmit = (event) => {
		event.preventDefault();
		navigate(fromPage, {replace: true})
	}
    return (
        <>	
		<div className="dashboard-auto-wrapper">
			<div className="create-from">
				<div className="create-from-steps active" id="createstep2">
					<h4 className="text-center">Create Happy Hour Drink</h4>					
					<form onSubmit={handleSubmit}>
					<div className="create-form-inner mt-4">
						<div className="form-group mb-3">
							<label className="form-label">Select Day</label>
							<div className="d-flex flex-row justify-content-between">
								<label className="custom-check">Monday
									<input type="radio" name="deals"/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-check">Tuesday
									<input type="radio" name="deals"/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-check">Wednesday
									<input type="radio" name="deals"/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-check">Thursady
									<input type="radio" name="deals"/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-check">Friday
									<input type="radio" name="deals"/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-check">Saturday
									<input type="radio" name="deals"/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-check">Sunday
									<input type="radio" name="deals"/>
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className="form-group mb-4">
									<label className="form-label">Start Time</label>
									<div className="input-right-icon">
										<input className="form-control"/>
										<i className="far fa-clock"></i>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group mb-4">
									<label className="form-label">End Time</label>
									<div className="input-right-icon">
										<input className="form-control"/>
										<i className="far fa-clock"></i>
									</div>
								</div>
							</div>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Liquor type</label>
							<select className="form-control">
								<option>Beer</option>
								<option>Wine</option>
								<option>Cider</option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Brand</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Material</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Price</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
					</div>
					<div className="create-from-btn text-center">
						<button className="btn btn-dark">Save</button>
					</div>
					</form>
				</div>
			</div>
		</div>
        </>
    );
};

export default DailyDrinkCreate;