import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch, faCheck } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import King from '../../assets/images/king.png';
import Filter from '../../assets/images/filter-ico.svg';
import apiService from '../../core/apiService';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { REACT_APP_LIMIT } from '../../apiServices/apiServices';
const PendingPayment = () => {
    const [isFilter, setisFilter] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);

    //     const limit = process.env.REACT_APP_LIMIT;

    //     console.log(limit, "limit")
    const navigate = useNavigate()
    const [appliedFilters, setAppliedFilters] = useState({
        search: '',
        start_date: '',
        end_date: '',

    })

    const [restaurantList, setRestaurantList] = useState([]);

    const [restaurant, setRestaurant] = useState([]);

    const [show, setShow] = useState(false);
    const [inputValue, setInputValue] = useState('')
    const [total, setTotal] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const [refresh, setRefresh] = useState(false)
    const [modalData, setModalData] = useState({
        loading: false,
        data: null,
        title: '',
        message: '',
        type: ''
    });


    const showFilter = () => {
        setisFilter(isFilter === false ? true : false);
        if (isFilter === true) return
        // setCurrentPage(1)

    };
    const handleClose = () => {
        setShow(false);
        setModalData({
            loading: false,
            data: null,
            title: '',
            message: ''
        })
    }
   

    const restaurantAction = () => {
        setModalData({
            ...modalData,
            loading: true
        });
        apiService.post('/restaurants/update/' + modalData.data.id, {
            enabled: 2
        }, ({ err, data }) => {
            if (err) return console.log(err);
            setRestaurantList(restaurantList.filter(item => item.id !== modalData.data.id));
            handleClose();
        });
    }
    const updateData = (page) => {
        setCurrentPage(page)
    }
    const handleSerach = (args) => {
        let params = {
            pageno: currentPage,
            subscription: 0,
            search: appliedFilters.search,
            start_date: appliedFilters.start_date,
            end_date: appliedFilters.end_date
        }
        if (args?.filters) {
            params = { ...params, ...args.filters };
        }
        setDataLoading(true);
        apiService.get('/restaurants/get', params, ({ err, data }) => {
            if (err) return console.log(err);     

            setRestaurantList(data.data.rows);
            // const filteredRestaurants = data.data.rows.filter(restaurant => (
            //     restaurant.restaurantsubscription?.susbcription_id == null
            // ));

            setRestaurantList(data.data.rows);
            // setTotal(filteredRestaurants.length);
            setDataLoading(false);
            setisFilter(false)
            setTotal(data.data.count)
            //    setRestaurant(data.data.rows)
            setDataLoading(false);
            setisFilter(false);
            setisFilter(false);
        })
    }
    const resetFilters = () => {
        setAppliedFilters({
            search: '',
            start_date: '',
            end_date: '',
        });
        handleSerach({
            filters: {
                search: '',
                start_date: '',
                end_date: '',
            },
        });
    }
    const sendReminder = (id) => {
        console.log(id, "item")
        apiService.post('/send-reminder', { id }, ({ err, data }) => {
            if (err) return console.log(err);
            setRefresh(!refresh)
        });

    }


    useEffect(() => {

        handleSerach({
            filters: appliedFilters
        })
    }, [refresh, currentPage]);
    console.log(restaurantList, "hguihjhkliji")
    const lastPage = Math.ceil(total / REACT_APP_LIMIT)
    console.log(lastPage, "lastpage")
    const nextPage = () => {
        if (currentPage < lastPage) {
            updateData(currentPage + 1)
        }
    }
    const previousPage = () => {
        updateData(currentPage - 1)
    }

    return (
        <>
            <div className="dashboard-top-btn">
                <div className='back-redirect'>
                    <button className="btn btn-default bg-dark filter-btn text-light" onClick={() => navigate(-1)}>Back</button>
                </div>
                <div className="table-filter">
                    <button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
                </div>

            </div>
            <div className="table-sec common-table margin-t30">
                <div className="table-responsive">
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th>Restaurant Name/Email</th>
                                <th>Contact no</th>
                                <th>Whatson_id</th>
                                <th>Register Date</th>
                                <th>View</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataLoading === true ? <tr><td colSpan="6" className="text-center">
                                    <Spinner />
                                </td></tr> :
                                    restaurantList.length ?
                                        restaurantList.map((item, index) => (

                                            <tr>

                                                <td >
                                                    {item.restaurant_name}
                                                    <br />
                                                    {item.restaurant_email}
                                                    {item.success == 0 ?
                                                        <>{item.verify_by_admin ? <span class="badge rounded-pill bg-success"> <FontAwesomeIcon icon={faCheck} /> Verified</span> : <span class="badge rounded-pill bg-secondary">  Not Verified</span>} </> :
                                                        <span class="badge rounded-pill bg-success"> <FontAwesomeIcon icon={faCheck} /> Verified</span>
                                                    }
                                                </td>

                                                <td>{item.restaurant_phone_number}</td>
                                                <td>{item.id}</td>
                                                <td>{moment(item.created_at).format('DD-MMM, YYYY')}</td>
                                                <td><Link to={`/accounts/activeview-restaurants?id=${item.id}`}>View</Link></td>
                                                <td className="table-action table-actions">
                                                    <button
                                                        // onClick={() => handleShow({
                                                        //     data: { id: item.id },
                                                        //     title: 'Suspend Restaurant - ' + item.restaurant_name,
                                                        //     message: 'Are you sure you want to suspend this restaurant account?',
                                                        //     type: 'suspend'
                                                        // })}
                                                        class="btn btn-danger btn-suspend reminder" onClick={() => { sendReminder(item.id) }} >Send Reminder</button>
                                                </td>
                                            </tr>
                                        )) :
                                        <tr><td colSpan="6" className="text-center">
                                            <p>No Restaurants Found</p>
                                        </td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {isFilter === true ?
                <>
                    <div className="popup-overlay show"></div>
                    <div id="filter-pop" className='show'>
                        <div className="filter-pop-inner">
                            <div className="filter-head">
                                <h4>Filters</h4>
                                <button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
                            </div>
                            <div className="filter-body">
                                <div className="mb-4">
                                    <div className="input-icon">
                                        <span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
                                        <input
                                            value={appliedFilters.search}
                                            onChange={(e) => setAppliedFilters({ ...appliedFilters, search: e.target.value })}
                                            type="text" className="form-control" placeholder="Type here..." />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <div className="input-icon">
                                            <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                            <input value={appliedFilters.start_date}
                                                onChange={(e) => setAppliedFilters({ ...appliedFilters, start_date: e.target.value })}
                                                type="date" className="form-control" placeholder="Start Date" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-icon">
                                            <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                            <input value={appliedFilters.end_date}
                                                onChange={(e) => setAppliedFilters({ ...appliedFilters, end_date: e.target.value })}
                                                type="date" className="form-control" placeholder="End Date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-form-btns">
                                <button className="btn btn-dark full-width-btn add-couple" onClick={() => handleSerach({
                                    filters: appliedFilters
                                })}>Apply Filter</button>
                                <button className="btn btn-light full-width-btn" onClick={resetFilters}>
                                    Reset Filters
                                </button>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                        <div className="custom-pop-inner">
                            <div className="custom-pop-box">
                                <div id="add-user-form">
                                    <div className="custom-pop-body">
                                        <h4 className="pop-title text-center">{modalData.title}</h4>
                                        <p className='text-center'>{modalData.message}</p>
                                    </div>
                                    <div className="custom-pop-footer">
                                        <div className="text-center">
                                            <button type="button" onClick={restaurantAction} disabled={modalData.loading} className="btn btn-dark btn-long">{
                                                modalData.loading ? <Spinner /> : 'Yes'
                                            }</button>
                                            <button type="button" onClick={handleClose} className="btn btn-long">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {
                        lastPage > 1 ?
                            <>

                                {<ul className="pagination justify-content-center">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={previousPage}>
                                            Previous
                                        </button>
                                    </li>
                                    {Array.from({ length: lastPage }).map((_, index) => (
                                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => updateData(index + 1)}>
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                                        <button className="page-link" onClick={nextPage}>
                                            Next
                                        </button>
                                    </li>
                                </ul>
                                }
                            </> : <></>
                    }
                </>
            }
        </>
    );
};

export default PendingPayment;