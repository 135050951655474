import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router';

const RequestAdmin = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);
	const navigate = useNavigate()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return (
        <>		
		<div className="dashboard-top-btn">
		<div className='back-redirect'>
                <button  className="btn btn-default bg-dark filter-btn text-light" onClick={()=>navigate(-1)}>Back</button>
                </div>
			<div className="table-filter">
				<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="assets/images/filter-ico.svg"/>Filters</button>
			</div>
			<button className="btn btn-dark" onClick={handleShow}><FontAwesomeIcon icon={faPencilAlt} /> Create</button>
		</div>
		<div className="table-sec common-table margin-t30">
					<div className="table-responsive">
						<table className="table white-td-table">
							<thead>
								<tr>
									<th>Type</th>
									<th>Subject</th>
									<th>Description</th>											
									<th>Date & Time</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Food</td>
									<td>Pink Pasta</td>
									<td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</td>
									<td>07 Nov, 2022    11:54 AM</td>
								</tr>
								<tr>
									<td>Food</td>
									<td>Masala Dosa</td>
									<td>Lorem Ipsum is not simply random text.</td>
									<td>07 Nov, 2022    11:54 AM</td>
								</tr>	
								<tr>
									<td>Liqour</td>
									<td>Lime Soda</td>
									<td>It is a long established fact that a reader will be distracted</td>
									<td>08 Nov, 2022    01:12 PM</td>
								</tr>						
							</tbody>
						</table>
					</div>
				</div>
		{isFilter === true ?
		<>
		<div className="popup-overlay show"></div>
		<div id="filter-pop" className='show'>
			<div className="filter-pop-inner">
				<div className="filter-head">
					<h4>Filters</h4>
					<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
				</div>
					<div className="filter-body">
						<div className="mb-4">
							<div className="input-icon">
								<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
								<input type="text" className="form-control" placeholder="Type here..."/>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-md-6">
								<div className="input-icon">
									<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
									<input type="text" className="form-control" placeholder="Start Date"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="input-icon">
									<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
									<input type="text" className="form-control" placeholder="End Date"/>
								</div>
							</div>
						</div>
					</div>
					<div className="filter-form-btns">
						<button className="btn btn-dark full-width-btn add-couple" onClick={showFilter}>Apply Filter</button>
					</div>
			</div>
		</div>
		</> : <>
		<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
				<button type="button" className="btn-close" onClick={handleClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
						<div className="custom-pop-body">
							<h4 className="pop-title text-center">Admin Communication</h4>
							<div className="pop-form">
								<div className="mb-3">
									<label>Type</label>
									<select className="form-control">
										<option></option>
									</select>
									<span className="error name-error">This field is required*</span>
								</div>
								<div className="mb-3">
									<label>Subject</label>
									<input type="text" className="form-control"/>
									<span className="error name-error">This field is required*</span>
								</div>
								<div className="mb-5">
									<label>Description</label>
									<textarea id="email" className="form-control"></textarea>
									<span className="error email-error">This field is required*</span>
								</div>
							</div>
						</div>
						<div className="custom-pop-footer">
							<div className="text-center">
								<button type="submit" onClick={handleClose} className="btn btn-dark btn-long">Send</button>
							</div>
						</div>
					</div>
					</div>
				</div>
		</Modal>
		</>
}
        </>
    );
};

export default RequestAdmin;