import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye, faTrash, faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { Link, useAsyncError } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditorState, convertFromHTML, convertToRaw, ContentState } from 'draft-js';
import restaurantimage from '../../assets/images/restaurant-image.png';
import DocumentPDf from '../../assets/images/dummy.pdf';
import apiService from '../../core/apiService';
import moment from 'moment/moment';
import DailySpecialFoodListing from '../DailySpecial/food';
import HappyHourFoodListing from '../HappyHour/food';
import GameSpecialFoodListing from '../GameDailySpecial/food';
import DailySpecialDrink from '../DailySpecial/drink';
import GameSpacialDrink from '../GameDailySpecial/drink';
import HappyHourDrink from '../HappyHour/drink';
import OnTapDrink from '../OnTap/drink';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';

const ActiveView = () => {
	const navigate = useNavigate();
	const [restaurantData, setRestaurantData] = useState([]);
	const { search } = useLocation();
	const restaurant_id = new URLSearchParams(search).get('id');
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/dashboard';
	const [isPayment, setisPayment] = useState(false);
	const [specialData, setSpecialData] = useState([]);
	const [activePlan, setActivePlan] = useState(null);
	const [currentSandwich, setCurrentSandwich] = useState(null);
	const [documents, setDocuments] = useState(null);
	const [communications, setCommunications] = useState(null);
	const [requests, setRequests] = useState(null);
	const [specialType, setSpecialType] = useState('food');
	const [text, setEditorState] = useState('')
	const planPremium = ["plan-box"];
	const planBasic = ["plan-box"];
	const handleSubmit = () => {
		setisPayment(isPayment === false ? true : false);
		setTimeout(() => {
			navigate(fromPage, { replace: true })
		}, 2000);
	}
	const baseUrl = process.env.REACT_APP_URL + '/public/';
	const [plan, setPlan] = useState('');
	const [isDisabled, setDisabled] = useState(false);
	const [selectedSpecialTab, setSelectedSpecialTab] = useState(1);
	const [followersList, setFollowersList] = useState(null);

	const [id, setId] = useState('')
	const [refresh, setRefresh] = useState(false)
	const [show, setShow] = useState(false);
	const [dataLoading, setDataLoading] = useState(true	);
	const handleClose = () => { setShow(false); setId('') }
	const handleShow = (id) => { setShow(true); setId(id) };


	const handleUpdate = () => {

		apiService.post(`/verify/${restaurant_id}`, { verify_by_admin: 1 }, ({ err, data }) => {

			console.log(data, "data")
			if (err) return console.log(err);
			else {
				setRefresh(!refresh);
				handleClose()
			}
		});


	}
	const changePlanPreminum = () => {
		setPlan('premium');
		planBasic.push('');
		setDisabled(true);
	}
	const changePlanBasic = () => {
		setPlan('basic');
		planBasic.push('selected');
		planPremium.push('');
		setDisabled(true);
	}
	if (plan === 'basic') {
		planBasic.push('selected');
		planPremium.push('');
	}
	if (plan === 'premium') {
		planBasic.push('');
		planPremium.push('selected');
	}

	console.log(restaurantData, "restajhjdhf")

	useEffect(() => {
		apiService.get('/restaurants/view', { id: restaurant_id }, ({ err, data }) => {
			if (err) return console.log(err);
			console.log(data.data, "datadata")
			setRestaurantData(data.data);
			setDataLoading(false);


		});
		apiService.get('/specials', {}, ({ err, data }) => {
			if (err) return console.log(err);
			setSpecialData(data.data);
		})
	}, [refresh]);

	const getCurrentPlan = () => {
		apiService.get('/restaurants/subscription', { restaurant_id: restaurant_id }, ({ err, data }) => {
			if (err) return console.log(err);
			if(data.data?.length > 0 && data.data[0].susbcription_id == 0){
				setActivePlan([]);
			}else {
				setActivePlan(data.data);
			}
			console.log(data.data, 'hsgijashakjsh')
		
		});
	};

	const getDocuments = () => {
		apiService.get('/restaurants/documents', { restaurant_id: restaurant_id }, ({ err, data }) => {
			if (err) return console.log(err);
			setDocuments(data.data);
		});
	}

	const getSandWich = () => {
		apiService.get(`/restaurants/get-sandwich?restaurant_id=${restaurant_id}&enabled=1`, {}, ({ err, data }) => {
			if (data.success) {
				setCurrentSandwich(data.data);
				const decodedSandwichText = decodeURIComponent(data?.data[0]?.sandwichText);
				let draft = convertFromHTML(decodedSandwichText);
				const state = ContentState.createFromBlockArray(
					draft.contentBlocks,
					draft.entityMap,
				);
				const editorState = EditorState.createWithContent(state);

				// Convert EditorState to text for rendering
				const text = editorState.getCurrentContent().getPlainText();

				// Set the text state
				setEditorState(text);
			} else {
				console.log(err);
			}
		}
		);
	};

	const getCommunication = () => {
		apiService.get('/restaurants/communications', { restaurant_id: restaurant_id }, ({ err, data }) => {
			if (err) return console.log(err);
			setCommunications(data.data);
		});
	};

	const getRequests = () => {
		apiService.get('/restaurants/requests', { restaurant_id: restaurant_id }, ({ err, data }) => {
			if (err) return console.log(err);
			setRequests(data.data);
		});
	};

	const getFollowers = () => {
		apiService.get('/restaurants/followers', { restaurant_id: restaurant_id }, ({ err, data }) => {
			if (err) return console.log(err);
			setFollowersList(data.data);
		});
	}


	const restaurantAction = () => {

		apiService.post('/verify' + restaurant_id, {
			enverify_by_adminabled: 1
		}, ({ err, data }) => {
			if (err) return console.log(err);

		});
	}

	const onMainTabChange = (e) => {
		console.log(e);
		if (e === 'membership' && !activePlan) {
			getCurrentPlan();

		};
		if (e === 'sandwich' && !currentSandwich) {
			getSandWich()
		}
		if (e === 'documents' && !documents) {
			getDocuments()
		}
		if (e === 'communication' && !communications) {
			getCommunication()
		}
		if (e === 'requests' && !requests) {
			getRequests()
		}
		if (e === 'followers' && !followersList) {
			getFollowers()
		}
	};

	const onSpecialTabChange = (e) => {
		console.log(e);
		setSelectedSpecialTab(parseInt(e));
	}

	const FoodsListing = {
		dailySpecial: selectedSpecialTab === 1 && <DailySpecialFoodListing restaurant_id={restaurant_id} />,
		gameDaySpecial: selectedSpecialTab === 2 && <GameSpecialFoodListing restaurant_id={restaurant_id} />,
		happyHourSpecial: selectedSpecialTab === 3 && <HappyHourFoodListing restaurant_id={restaurant_id} />
	};

	const DrinksListing = {
		dailySpecial: selectedSpecialTab === 1 && <DailySpecialDrink restaurant_id={restaurant_id} />,
		gameDaySpecial: selectedSpecialTab === 2 && <GameSpacialDrink restaurant_id={restaurant_id} />,
		happyHourSpecial: selectedSpecialTab === 3 && <HappyHourDrink restaurant_id={restaurant_id} />,
		onTap: selectedSpecialTab === 4 && <OnTapDrink restaurant_id={restaurant_id} />,
	};
	console.log(restaurantData.restaurant_pic, 'restaurantData?.restaurant_pic')

	console.log(activePlan, "kkkkkkk")
	return (
		<>
			<div className="dashboard-auto">
				<div className="row team-profile">
					<div className="col-lg-12">
						<div className="d-flex justify-content-end">
							<div className="profile-img-box view-img">
								{
                                  dataLoading === true ?
								  <div className='spinner-code'> <div colSpan="10" className="text-center">
										<Spinner />
									</div> </div>:
										<>
											<div className="team-logo">
												{restaurantData?.restaurant_pic ?
													<img alt="" src={baseUrl + restaurantData?.restaurant_pic} /> :
													<img alt="" src={restaurantimage} style={{ width: '500px' }} />
												}
											</div>
											<div className="pteam-detail">
												<ul>
													<li><span>Restaurant Name:</span> {restaurantData?.restaurant_name}</li>
													<li><span>Email:</span> {restaurantData?.restaurant_email}</li>
													<li><span>Contact no:</span> {restaurantData?.restaurant_phone_number}</li>
													<li><span>Address:</span> {restaurantData?.restaurantlocation?.restaurant_address_line1}</li>
												</ul>
												<ul style={{ color: 'red' }}>
													{restaurantData?.success === 0 ? <>{restaurantData?.verify_by_admin ? "" : 'Verification Pending'}</> : ''}
												</ul>
												<ul>
													{restaurantData?.success === 0 ?
														<>
															{restaurantData?.verify_by_admin ?
																<></> :
																<button className="btn btn-success" onClick={() => handleShow(restaurant_id)}>Verify</button>}
														</> :
														<></>
													}
												</ul>
											</div>
										</>
								}
							</div>

							<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
								<button type="button" className="btn-close" onClick={handleClose}></button>
								<div className="custom-pop-inner">
									<div className="custom-pop-box">
										<div id="add-user-form">
											<div className="custom-pop-body">
												<h4 className="pop-title text-center">Are You Sure you want to Verify this account</h4>
											</div>
											<div className="custom-pop-footer">
												<div className="text-center">
													<button type="button" onClick={handleClose} className="btn btn-secondary mx-2">
														No
													</button>
													<button type="button" onClick={handleUpdate} className="btn btn-danger">
														Yes
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Modal>
							{/* <div className="team-edit">
								<button className="btn btn-inactive">Inactive Account</button>
							</div> */}
						</div>
					</div>
				</div>
			</div>

			<div className="tab-content team-view-table" id="team-table">
				<Tabs defaultActiveKey="specials" className="mb-3 user-navbar following-table" onSelect={onMainTabChange}>
					<Tab eventKey="specials" title="Specials">
						<div className="food-select">
							<select value={specialType} className="form-control" onChange={(e) => setSpecialType(e.target.value)}>
								<option value="food">Food </option>
								<option value="drink">Drinks</option>
							</select>
						</div>
						<div className="tab-content restaurant_special_tab" id="common-tabs-section">
							<Tabs onSelect={onSpecialTabChange} defaultActiveKey={1} id="food-tab" className="mb-3">
								{
									specialData.length ?
										specialData.map(x => (
											(x.id === 4 && specialType === 'food') ? <></> :
												<Tab eventKey={x.id} title={x.special_name}>
													{
														specialType === 'food' ?
															<>
																{x.id === 1 ? FoodsListing.dailySpecial : <></>}
																{x.id === 2 ? FoodsListing.gameDaySpecial : <></>}
																{x.id === 3 ? FoodsListing.happyHourSpecial : <></>}
															</> :
															<div className='mt-10' style={{ marginTop: '85px' }}>
																{x.id === 1 ? DrinksListing.dailySpecial : <></>}
																{x.id === 2 ? DrinksListing.gameDaySpecial : <></>}
																{x.id === 3 ? DrinksListing.happyHourSpecial : <></>}
																{x.id === 4 ? DrinksListing.onTap : <></>}
															</div>
													}
												</Tab>
										)) : <></>
								}
							</Tabs>
							{/* <Tabs defaultActiveKey="dailyspecials" id="drink-tab" className="mb-3">
								<Tab eventKey="dailyspecials" title="Daily Specials">
									<div className="table-sec common-table margin-t30">
										<div className="table-responsive">
											<table className="table white-td-table">
												<thead>
													<tr>
														<th>Day</th>
														<th>Start Time</th>
														<th>End Time</th>
														<th>Region</th>
														<th>Red/ White</th>
														<th>Container Type</th>
														<th>Size</th>
														<th>Price</th>
														<th>Description</th>
											<th>Special Tags</th>
											<th>Standard Tags</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>Monday</td>
														<td>5.00 pm</td>
														<td>8.00 pm</td>
														<td>Italy</td>
														<td>Red</td>
														<td>Glass</td>
														<td>2</td>
														<td>$30</td>
														<td>Red wine in glass $30</td>
													</tr>
													<tr>
														<td>Monday</td>
														<td>5.00 pm</td>
														<td>7.00 pm</td>
														<td>Mexico</td>
														<td>White</td>
														<td>Bottle</td>
														<td></td>
														<td>20%</td>
														<td>White wine on 20% off</td>
													</tr>
													<tr>
														<td>Monday</td>
														<td>5.00 pm</td>
														<td>7.00 pm</td>
														<td>Italy</td>
														<td>All wine</td>
														<td>Bottle</td>
														<td></td>
														<td>10%</td>
														<td>All wines on 10% off</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</Tab>
								<Tab eventKey="happyhours" title="Happy Hours">
									<div className="table-sec common-table margin-t30">
										<div className="table-responsive">
											<table className="table white-td-table">
												<thead>
													<tr>
														<th>Day</th>
														<th>Start Time</th>
														<th>End Time</th>
														<th>Name</th>
														<th>Container type</th>
														<th>Size</th>
														<th>Price</th>
														<th>Description</th>
											<th>Special Tags</th>
											<th>Standard Tags</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>Tuesday</td>
														<td>5.00 pm</td>
														<td>8.00 pm</td>
														<td>Bud light</td>
														<td>can</td>
														<td></td>
														<td>$10</td>
														<td>Bud light is for $10 a glass</td>
													</tr>
													<tr>
														<td>Wednesday</td>
														<td>5.00 pm</td>
														<td>7.00 pm</td>
														<td>Bud light</td>
														<td>Bottel</td>
														<td></td>
														<td>$5</td>
														<td>All Bud light Beer are on $5</td>
													</tr>
													<tr>
														<td>Monday</td>
														<td>5.00 pm</td>
														<td>8.00 pm</td>
														<td>Bud light</td>
														<td>Glass</td>
														<td>2 ounce</td>
														<td>$30</td>
														<td></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</Tab>
								<Tab eventKey="Game" title="Game Day Special">
									<div className="table-sec common-table margin-t30">
										<div className="table-sec common-table margin-t30">
											<div className="table-responsive">
												<table className="table white-td-table">
													<thead>
														<tr>
															<th>Day</th>
															<th>Start Time</th>
															<th>End Time</th>
															<th>Name</th>
															<th>Container Type</th>
															<th>Size</th>
															<th>Price</th>
															<th>Description</th>
											<th>Special Tags</th>
											<th>Standard Tags</th>
															
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Tuesday</td>
															<td>5.00 pm</td>
															<td>8.00 pm</td>
															<td>Stonewell Cider</td>
															<td>can</td>
															<td></td>
															<td>$10</td>
															<td>Stonewell Cider is for $10 a glass</td>
														</tr>
														<tr>
															<td>Wednesday</td>
															<td>5.00 pm</td>
															<td>7.00 pm</td>
															<td>Stonewell Cider</td>
															<td>Bottle</td>
															<td></td>
															<td>$5</td>
															<td>All Stonewell Cider are on $5 off</td>
														</tr>
														<tr>
															<td>Monday</td>
															<td>5.00 pm</td>
															<td>7.00 pm</td>
															<td>Stonewell Cider</td>
															<td>Glass</td>
															<td> 2 ounce</td>
															<td>$30</td>
															<td></td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</Tab>
							</Tabs> */}
						</div>

					</Tab>
					<Tab eventKey="membership" title="Membership">
						{activePlan === null ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : activePlan?.length ? (
							<div className="col-md-4">
								<div className={planPremium.join(' ')}>
									<h4>{activePlan[0]?.subscription?.subscription_name}</h4>
									<div className="plan-price">
										<span className="price-con">${activePlan[0]?.subscription?.subscription_price}</span>
										<span>/month</span>
									</div>
									<ul className="plan-point">
										{activePlan[0]?.subscription?.subscriptionfeatures.map((x, index) => (
											<li key={index}>{x.feature?.feature_name}</li>
										))}
									</ul>
								</div>
							</div>
						) : (
							<div className="text-center">
								<p>No Membership Found</p>
							</div>
						)}
					</Tab>
					<Tab eventKey="followers" title="Followers">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Sr. No.</th>
											<th>Followers</th>
											<th>Location</th>
											<th>Follow Date</th>
										</tr>
									</thead>
									<tbody>
										{followersList === null ? (
											<tr>
												<td colSpan="4" className="text-center">
													<Spinner />
												</td>
											</tr>
										) : followersList?.length ? (
											followersList.map((item, i) => (
												<tr key={item.id}>
													<td>{i + 1}</td>
													<td className="table-user-cross-icon">
														<img alt="" src="/assets/images/Group.png" />
														{item.user?.fullname || item.user?.user_name}
													</td>
													<td>{item.user?.currentCity}</td>
													<td>{moment(item.created_at).format('DD-MMM, YYYY HH:MM')}</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={4}>
													<p className='text-center'>No Followers Found</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
					<Tab eventKey="requests" title="Request to Admin">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Type</th>
											<th>Name</th>
											<th>Description</th>
											<th>Date & Time</th>
										</tr>
									</thead>
									<tbody>
										{requests === null ? (
											<tr>
												<td colSpan="4" className="text-center">
													<Spinner />
												</td>
											</tr>
										) : requests?.length ? (
											requests.map((x, index) => (
												<tr key={index}> {/* Add key prop here */}
													<td>{x.type?.type_name}</td>
													<td>{x.subject}</td>
													<td>{x.description}</td>
													<td>{moment(x.created_at).format('DD-MMM, YYYY HH:MM')}</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={4}>
													<p className='text-center'>No Requests Found</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>

					<Tab eventKey="communication" title="Communication">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Title</th>
											<th>Message</th>
											<th>Date & Time</th>
										</tr>
									</thead>
									<tbody>
										{communications === null ? (
											<tr>
												<td colSpan="3" className="text-center">
													<Spinner />
												</td>
											</tr>
										) : communications?.length ? (
											communications.map((communication, index) => (
												<tr key={index}>
													<td>{communication.title}</td>
													<td>{communication.message}</td>
													<td>{moment(communication.created_at).format('DD-MMM, YYYY HH:mm')}</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="3">
													<p className='text-center'>No Communication Found</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>

					<Tab eventKey="sandwich" title="Sandwich">
						{currentSandwich === null ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : currentSandwich.length ? (
							<div className="col-md-4">
								{text}
								{/* <div dangerouslySetInnerHTML={{ __html: currentSandwich[0]?.sandwichText }} /> */}
							</div>
						) : (
							<p className='text-center'>No Sandwich Found</p>
						)}
						{/* <div className={'Sandwich-board'}>
        <div className="top-sandwitch">BUY AN MEAL FOR
            AN ESSENTIAL WORKERS</div>
        <div className="footer-sandwitch">
            <span>Help out those who always help us</span>
        </div>
    </div> */}
					</Tab>

					<Tab eventKey="documents" title="Document Uploaded">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table table-borderless first-table">
									<thead>
										<tr>
											<th> Sr. No</th>
											<th>Document Name</th>
											<th>View</th>
										</tr>
									</thead>
									<tbody>
										{documents === null ? (
											<tr>
												<td colSpan="3" className="text-center">
													<Spinner />
												</td>
											</tr>
										) : documents?.length ? (
											documents.map((document, index) => (
												<tr key={index}>
													<td>{index + 1}</td>
													<td>{document.restaurant_document}</td>
													<td><a href={document.url} target="_blank">View</a></td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="3">
													<p className='text-center'>No Documents Uploaded</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
						{/* Uncommented section for displaying uploaded PDF */}
						{/* <div className="col-md-12 Pdf-uploaded">
        <div className="uploaded">
            <embed
                src="../../assets/images/dummy.pdf"
                type="application/pdf"
                frameBorder="0"
                scrolling="auto"
                height="500px"
                width="100%"
            ></embed>
        </div>
    </div> */}
					</Tab>

				</Tabs>
			</div>

		</>
	);
};

export default ActiveView;