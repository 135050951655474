import React,{useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';


const CreateSpecial = () => {
	const [Step, setStep] = useState(0);
	const [createdSuccess, setcreatedSuccess] = useState(false);
	
	const nextStep = () => {
		setStep(Step + 1);
	  };
	  const backStep = () => {
		setStep(Step - 1);
	  };
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/on-tap/drink';
	const createPage = location.state?.from?.pathname || '/create-special';

	const handleSubmit = () => {
		setcreatedSuccess(createdSuccess === false ? true : false);
		setTimeout(() => {
			navigate(fromPage, {replace: true})
		}, 3000);
	}

	  const [liquorType, setliquorType] = useState("wine");
	  const changeliquorType = (e) => {
		  setliquorType(e.target.value);
	  };

	const back = () => {
		navigate(createPage, {replace: true})
	  };
	
    return (
        <>	
		<div className="dashboard-auto-wrapper">
		<div className="create-from">
		{Step === 0 &&
			<div className="create-from-steps active">
				<h4>Please Select Type </h4>	
				<div className="create-form-inner mt-4">
					<div className='row'>
						<div className='col-md-8'>
							<div className="form-group mb-5">
								<label className="form-label">Type</label>								
								<select value={liquorType} onChange={changeliquorType} className="form-control">
									<option value="wine">Wine</option>
									<option value="beer">Beer</option>
									<option value="cider">Cider</option>
								</select>
							</div>	
						</div>	
					</div>					
				</div>
				<div className="create-from-btn">
					<button className="btn btn-transparent" onClick={back}>Back</button>
					<button className="btn btn-dark" onClick={nextStep}>Next</button>
				</div>
			</div>
			}
			{Step === 1 && liquorType === 'wine' &&
				<div className="create-from-steps active">
					<h4>Wine!</h4>		
					<div className="create-form-inner mt-4">
						<div className="form-group mb-4">
							<label className="form-label">Region</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Red/ White</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Type</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Name</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Description</label>
							<input className="form-control"/>
						</div>
					</div>
					<div className="create-from-btn mt-5">
						<button className="btn btn-transparent" onClick={backStep}>Back</button>
						<button className="btn btn-dark" onClick={handleSubmit}>save</button>
					</div>
				</div>
			}
			{Step === 1 && liquorType === 'beer' &&
				<div className="create-from-steps active">
				<h4>Beer!</h4>		
				<div className="create-form-inner mt-4">
					<div className="form-group mb-4">
						<label className="form-label">Name</label>
						<select className="form-control">
							<option></option>
						</select>
					</div>
					<div className="form-group mb-4">
						<label className="form-label">Container Type</label>
						<select className="form-control">
							<option></option>
							<option>Draft</option>
							<option>Bottle</option>
							<option>Can</option>
						</select>
					</div>
					<div className="form-group mb-4">
						<label className="form-label">Price</label>
						<select className="form-control">
							<option></option>
						</select>
					</div>
					<div className="form-group mb-4">
						<label className="form-label">Description</label>
						<input className="form-control"/>
					</div>
				</div>
				<div className="create-from-btn mt-5">
					<button className="btn btn-transparent" onClick={backStep}>Back</button>
					<button className="btn btn-dark" onClick={handleSubmit}>save</button>
				</div>
			</div>
			}
			{Step === 1 && liquorType === 'cider' &&
				<div className="create-from-steps active">
					<h4>Cider</h4>		
					<div className="create-form-inner mt-4">
						<div className="form-group mb-4">
							<label className="form-label">Name</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Container Type</label>
							<select className="form-control">
								<option></option>
								<option>Draft</option>
								<option>Bottle</option>
								<option>Can</option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Price</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Description</label>
							<input className="form-control"/>
						</div>
					</div>
					<div className="create-from-btn mt-5">
						<button className="btn btn-transparent" onClick={backStep}>Back</button>
						<button className="btn btn-dark" onClick={handleSubmit}>save</button>
					</div>
				</div>
			}
			{createdSuccess === true ?
			<>
			<div className="common-message-pop show" id="created-success-pop">
				<div className="common-pop-inner">
					<div className="common-pop-box created-message-box">
						<h2>Hurray!</h2>
						<p className="message-txt">You just Created Specials!!</p>
					</div>
				</div>
			</div>
			</> : <></>
			}
			</div>
			</div>
        </>
    );
};

export default CreateSpecial;