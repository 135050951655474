import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import logo from '../../assets/images/logo.png';


const Register = () => {
    const navigate = useNavigate();
const location = useLocation();
const fromPage = location.state?.from?.pathname || '/verify-business';

const handleSubmit = (event) => {
    event.preventDefault();
    navigate(fromPage, {replace: true})
}
    
    return (
        <div>
            <div id="register-page">
		<div className="login-form">
			<div className="login-form-inner">
				<div className="login-logo">
					<img src={logo} alt="Whatson"/>
				</div>
				<div className="login-form-box">
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-md-6">
								<div className="mb-4">
									<label>Username</label>
									<input type="text" id="username" className="form-control" placeholder="Username"/>
									<span className="error username-error">Username is required*</span>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-4">
									<label>Password</label>
									<input type="password" id="password" className="form-control" placeholder="Password"/>
									<span className="error password-error">Password is required*</span>
								</div>
							</div>
						</div>
						<div className="mb-4">
							<label>Restaurant Name</label>
							<input type="text" id="email" className="form-control" placeholder="Restaurant Name"/>
							<span className="error email-error">Restaurant name is required*</span>
						</div>
						<div className="mb-4">
							<label>Restaurant Email</label>
							<input type="text" id="email" className="form-control" placeholder="Restaurant Email"/>
							<span className="error email-error">Restaurant Email is required*</span>
						</div>
						<div className="mb-4">
							<label>Phone number</label>
							<input type="text" id="contact" className="form-control" placeholder="Phone"/>
							<span className="error password-error">Phone is required*</span>
						</div>
						<div className="mb-4">
							<label>Instagram Link(Optional)</label>
							<input type="text" id="instalink" className="form-control" placeholder="Instagram.com/omegao_rion/?hl=en"/>
						</div>
						<div className="mb-4">
							<label>Add a profile or logo of your restaurant(Optional)</label>
							<div className="restaurant-photo-box">
								<div className="restaurant-photo-input">
									<FontAwesomeIcon icon={faPlus} />
								</div>
								<input type="file" className="photo-input"/>
							</div>
						</div>
						<div className="mb-3">
							<button className="btn btn-dark full-btn btn-lg signin-btn">Register</button>
						</div>
					</form>
				</div>
				<div className="form-bottom-link">Already have an account? <Link to='/'><b>Login Now</b></Link></div>
			</div>
		</div>
		<div className="login-img">
			<div className="login-img-inner">
				<div className="dashboard-title">
					<h3>WELCOME TO</h3>
					<h1>Your Restaurant Dashboard</h1>
					<p>Register to Access Dashboard</p>
				</div>
			</div>
		</div>
	</div>
        </div>
    );
};

export default Register;
