import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import logo from '../../assets/images/logo.png';
import apiService from '../../core/apiService';

const Loginpage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || '/';

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email is required'),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    apiService.post("/forgetpassword", values, ({ err, data }) => {
      setSubmitting(false);

      if (err) {
        console.log(err.err);
        return;
      }
      if (data?.data) {
        navigate(fromPage, { replace: true });
      }
    });
  };

  return (
    <div>
      <div id="login-page">
        <div className="login-form">
          <div className="login-form-inner">
            <div className="login-logo">
              <img src={logo} alt="Whatson" />
              <p>Get your login information</p>
            </div>
            <div className="login-form-box">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <div className="mb-4">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                        placeholder="Email"
                      />
                      <ErrorMessage name="email" component="span" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <button
                        type="submit"
                        className="btn btn-dark full-btn btn-lg signin-btn"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Sending...' : 'Get Password'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="login-img">
          <div className="login-img-inner">
            <div className="dashboard-title">
              <h3>WELCOME TO</h3>
              <h1>Admin Panel</h1>
              <p>Login to Access Dashboard</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginpage;
