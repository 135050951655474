import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';


const DailyDrinkCreate = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/game-day-special/drink';
	
	const handleSubmit = (event) => {
		event.preventDefault();
		navigate(fromPage, {replace: true})
	}
    return (
        <>	
		<div className="dashboard-auto-wrapper">
			<div className="create-from">
				<div className="create-from-steps active" id="createstep2">
					<h4 className="text-center">Create Game Day Special Drink</h4>					
					<form onSubmit={handleSubmit}>
					<div className="create-form-inner mt-4">
						<div className="form-group mb-4">
							<label className="form-label">Sport</label>
							<select className="form-control">
								<option></option>
							</select>
						</div> 
						<div className="form-group mb-4">
							<label className="form-label">Team</label>
							<select className="form-control">
								<option></option>
							</select>
						</div> 
						<div className="form-group mb-4">
							<label className="form-label">Liquor type</label>
							<select className="form-control">
								<option>Beer</option>
								<option>Wine</option>
								<option>Cider</option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Brand</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Material</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Price</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
					</div>
					<div className="create-from-btn text-center">
						<button className="btn btn-dark">Save</button>
					</div>
					</form>
				</div>
			</div>
		</div>
        </>
    );
};

export default DailyDrinkCreate;