import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import logo from '../../assets/images/logo.png';
import apiService from '../../core/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const Loginpage = () => {
  const [cookies, setCookie] = useCookies(['']);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const fromPage = location.state?.from?.pathname || '/dashboard';
	const [loading, setLoading] = useState(false);

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    apiService.post('/adminLogin', values, ({ err, data }) => {
      console.log(err, data);
      if (err) {
        setLoading(false);
        console.log(err.err);
        return;
      }
      if (data?.data) {
        setCookie('userToken', data.data.token);
        setCookie('userId', data.data.id);
        dispatch({ type: 'onLogin', payload: data.data });
        navigate(fromPage, { replace: true });
      }
    });
  };

  return (
    <div>
      <div id="login-page">
        <div className="login-form">
          <div className="login-form-inner">
            <div className="login-logo">
              <img src={logo} alt="Whatson" />
            </div>
            <div className="login-form-box">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="mb-4">
                      <label htmlFor="username">Username</label>
                      <Field
                        type="text"
                        id="username"
                        name="username"
                        className={`form-control ${
                          errors.username && touched.username ? 'is-invalid' : ''
                        }`}
                        placeholder="Username"
                      />
                      {errors.username && touched.username && (
                        <span className="invalid-feedback">{errors.username}</span>
                      )}
                    </div>
                    <div className="mb-4">
                      <label htmlFor="password">Password</label>
                      <Field
                        type="password"
                        id="password"
                        name="password"
                        className={`form-control ${
                          errors.password && touched.password ? 'is-invalid' : ''
                        }`}
                        placeholder="Password"
                      />
                      {errors.password && touched.password && (
                        <span className="invalid-feedback">{errors.password}</span>
                      )}
                    </div>
                    <div className="mb-4 forgot-password">
                      <label className="custom-check"></label>
                      <Link to="/forgot-password">Forgot username or Password?</Link>
                    </div>
                    <div className="mb-3">
                    <button className="btn btn-dark full-btn btn-lg signin-btn">{
										loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Login'
									}</button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="login-img">
          <div className="login-img-inner">
            <div className="dashboard-title">
              <h3>WELCOME TO</h3>
              <h1>Admin Panel</h1>
              <p>Login to Access Dashboard</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginpage;
