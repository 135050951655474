import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import profile from '../../assets/images/images.png';
import apiService from '../../core/apiService';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { generateImage } from '../../helpers/scrollToTop';

const UserManagementView = () => {
	const { id } = useParams();
	const [userData, setUserData] = useState(null);
	const [dataLoading, setDataLoading] = useState(true);
	const [searchedKeywords, setSearchedKeyWords] = useState([]);
	const [followingList, setFollowingList] = useState([]);
	const [tabDataLoading, setTabDataLoading] = useState(true);
	useEffect(() => {
		apiService.get('/users/' + id, {}, ({ err, data }) => {
			if (err) {
				console.error(err);
			} else {
				setUserData(data.data);
				setDataLoading(false);
				getFollowingList();
			}
		})
	}, []);

	const getSearchKeywords = () => {
		setTabDataLoading(true);
		apiService.get('/search_keywords', { user_id: id }, ({ err, data }) => {
			if (err) {
				console.error(err);
				setTabDataLoading(false);
			} else {
				setSearchedKeyWords(data.data);
				setTabDataLoading(false);
			}
		})
	};

	const getFollowingList = () => {
		setTabDataLoading(true);
		apiService.get('/users/fav/following', { user_id: id }, ({ err, data }) => {
			if (err) {
				console.error(err);
				setTabDataLoading(false);
			} else {
				console.log(data);
				setFollowingList(data.data);
				setTabDataLoading(false);
			}
		})
	}



	const onTabChange = (e) => {
		if (e === 'following' && followingList.length === 0) {
			getFollowingList();
		};
		if (e === 'search' && searchedKeywords.length === 0) {
			getSearchKeywords();
		}
	}

	console.log(userData?.fullname,'djfj')

	return (
		<>
			<div className="dashboard-auto">
				<div className="row">
					<div className="col-lg-12">
						<div className="d-flex view-profile">
							<div className="profile-img-box_1">
								<div className="cperson-img">
									
									<img src={generateImage(userData?.fullname ? userData?.fullname :''   )} alt='team_logo' width='100'  />
								</div>
								<div className="cperson-detail">
									<h4>{userData?.fullname || userData?.username}</h4>
									<p>{userData?.DateOfBirth ? moment().diff(userData.DateOfBirth  , 'years') + ' years' : '' ||  userData?.currentCity }</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="tab-content user-view-table" id="view-table">
					<Tabs onSelect={onTabChange} defaultActiveKey="following" id="following-table" className="mb-3 user-navbar">
						<Tab eventKey="following" title="Following">
							<div className="table-sec common-table margin-t30">
								<div className="table-responsive">
									<table className="table white-td-table">
										<thead>
											<tr>
												<th>Restaurant Name</th>
												<th>Location</th>
												<th>Followers</th>
												<th>Membership</th>

											</tr>
										</thead>
										<tbody>
											{
												tabDataLoading === true ? <tr><td colSpan="6" className="text-center">
													<Spinner />
												</td></tr> :
													followingList.filter(item=>item.userCOUNT != 0 ).length ? followingList.filter(item=>item.userCOUNT != 0 ).map((item) => (
														<tr >
															<td>{item.restaurant_name}</td>
															<td>{item.restaurantlocation.city}</td>
															<td>{item.totalCount}</td>
															<td>{item.restaurantsubscription.susbcription_id == 2 || item.restaurantsubscription.susbcription_id == 4  ? 'Premium' : 'Basic'}</td>	

														</tr>
													)) : <tr><td colSpan="6" className="text-center">No data found</td></tr>
											}
										</tbody>
									</table>
								</div>
							</div>
						</Tab>
						{/* <Tab eventKey="beer" title="Interest">
							<div className="table-sec common-table margin-t30 Beer-table ">
								<div className="table-responsive">
									<table className="table white-td-table">
										<thead>
											<tr>
												<th>Keyword(Counts)</th>


											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="keywords">
													<button className="btn btn-secondary keywords-btn">Kitchen Garden(7)</button>
													<button className="btn btn-secondary keywords-btn">Ritchmond Station(12)</button>
													<button className="btn btn-secondary keywords-btn">Shenanigans Pub + Patio(1)</button>
												</td>

											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</Tab> */}
						<Tab eventKey="search" title="Searching">
							<div className="table-sec common-table margin-t30 Beer-table ">
								<div className="table-responsive">
									<table className="table white-td-table">
										<thead>
											<tr>
												<th>Keyword(Counts)</th>
											</tr>
										</thead>
										<tbody>
											{tabDataLoading === true ? <tr><td colSpan="6" className="text-center">
												<Spinner />
											</td></tr> :
												<tr>
													<td className="keywords">
														{
															searchedKeywords?.search?.length ?
																searchedKeywords?.search?.map((item, index) => (
																	<button key={index} className="btn btn-secondary keywords-btn">{item.keyword}({item.count})</button>
																)) : <p className="text-center">No data found</p>
														}
														{/* <button className="btn btn-secondary keywords-btn">Kitchen Garden(7)</button>
													<button className="btn btn-secondary keywords-btn">Ritchmond Station(12)</button>
													<button className="btn btn-secondary keywords-btn">Shenanigans Pub + Patio(1)</button> */}
													</td>

												</tr>
											}
										</tbody>
									</table>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>



			</div>
		</>
	);
};

export default UserManagementView;