import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import apiService from '../../core/apiService';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import Filter from '../../assets/images/filter-ico.svg';
import { REACT_APP_LIMIT } from '../../apiServices/apiServices';
const UserManagementActive = () => {
	const [isFilter, setisFilter] = useState(false);
	
	const [show, setShow] = useState(false);
	const [modalData, setModalData] = useState({
		loading: false,
		data: null
	});
	const [total, setTotal] = useState([])
	const [currentPage, setCurrentPage] = useState(1)

	const [refresh, setRefresh] = useState(false)
	const [usersData, setUsersData] = useState([]);
	const [userActive, setActive] = useState([])
	const [dataLoading, setDataLoading] = useState(true);

	const navigate = useNavigate()
	const handleClose = () => {
		setShow(false);
		setModalData({
			loading: false,
			data: null
		})
	}
	const handleShow = (data) => {
		setShow(true);
		setModalData({
			...modalData,
			data
		})
	};
	const [appliedFilters, setAppliedFilters] = useState({
		search: '',
		start_date: '',
		end_date: '',

	})
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
		if (isFilter === true) return
		setCurrentPage(1)

	};
	// const showFilter = () => {
	// 	setisFilter(isFilter === false ? true : false);
	// 	let filter = []
	// 	if (isFilter === true) {
	// 		if (appliedFilters.start_date && appliedFilters.end_date && appliedFilters.search) {
	// 			appliedFilters.search = appliedFilters.search.toLowerCase()
	// 			filter = usersData.filter(item =>moment(moment(item.user.created_at).format('YYYY.MM.DD')).isBetween(appliedFilters.start_date, appliedFilters.end_date))
	// 			filter = filter.filter(item =>
	// 				moment().diff(item.user.DateOfBirth, 'years') == appliedFilters.search || item.user?.currentCity?.toLowerCase() == appliedFilters.search || appliedFilters.search.match(item.user.username?.toLowerCase() || item.user?.fullname?.toLowerCase() || ''))
	// 			console.log(filter, "filterefrekjrk")
	// 			setUsersData(filter);

	// 		}
	// 		else if (appliedFilters.search) {
	// 			appliedFilters.search = appliedFilters.search.toLowerCase()
	// 			filter = usersData.filter(item =>
	// 				moment().diff(item.user.DateOfBirth, 'years') == appliedFilters.search || item.user?.currentCity?.toLowerCase() == appliedFilters.search || appliedFilters.search.match(item.user.username?.toLowerCase() || item.user?.fullname?.toLowerCase() || ''))
	// 			console.log(filter, "filter")
	// 			setUsersData(filter);
	// 		}
	// 		else if (appliedFilters.start_date && appliedFilters.end_date) {

	// 			filter = usersData.filter(item =>
	// 				moment(moment(item.user.created_at).format('YYYY.MM.DD')).isBetween(appliedFilters.start_date, appliedFilters.end_date))

	// 			setUsersData(filter);

	// 		} else {
	// 			setUsersData(userActive)
	// 		}


	// 	} else {
	// 		setUsersData(userActive)
	// 	}

	// };
	console.log(userActive, "userActive")
		;
	const updateData = (page) => {
		setCurrentPage(page)
	}
	const handleSerach = (args) => {
		console.log(args, "args")
		let params = {
			pageno: currentPage,
			enabled: 1,
			search: appliedFilters.search,
			start_date: appliedFilters.start_date,
			end_date: appliedFilters.end_date
		}
		if (args?.filters) {
			params = { ...params, ...args.filters };
		}
		setDataLoading(true);
		console.log(params, "params")
		apiService.get('/mobile-users', params, ({ err, data }) => {
			if (err) return console.log(err);
			if (err) {
				console.log(err);
				setisFilter(false);
				setDataLoading(false);
				setUsersData([]);
				return;
			};
			console.log(data.data, "data.data")
			setUsersData(data.data.rows);
			setActive(data.data.rows)
			setTotal(data.data.count)
			setDataLoading(false);
			setisFilter(false);
			
		})
	}
	const resetFilters = () => {
		setAppliedFilters({
			search: '',
			start_date: '',
			end_date: '',
		});
		handleSerach({
			filters: {
			  search: '',
			  start_date: '',
			  end_date: '',
			},
		  });
	}


	useEffect(() => {
		setDataLoading(true);
		handleSerach({
			filters: appliedFilters
		})
	}, [currentPage]);

	const lastPage = Math.ceil(total / REACT_APP_LIMIT)
	console.log(lastPage, "lastpage")
	const nextPage = () => {
		if (currentPage < lastPage) {
			updateData(currentPage + 1)
		}
	}
	const previousPage = () => {
		updateData(currentPage - 1)
	}

	const suspendUser = () => {
		setModalData({
			...modalData,
			loading: true
		});
		apiService.post('/mobile-users/update/' + modalData.data.id, {
			enabled: 0
		}, ({ err, data }) => {
			if (err) return console.log(err);
			setUsersData(usersData.filter(item => item.user.id !== modalData.data.id));
			handleClose();
		});
	}

	// const sorting = [...usersData].sort((a, b) =>
	// 	// a.user.created_at>b.user.created_at ?1 :-1
	// 	moment(a.user.created_at).format('DD-MMM, YYYY HH:MM') > moment(b.user.created_at).format('DD-MMM, YYYY HH:MM') ? 1 : -1
	// );
	const sorting = [...usersData].sort((a, b) =>
		// a.user.created_at>b.user.created_at ?1 :-1
		moment(a.user.created_at).format('DD-MMM, YYYY HH:MM') > moment(b.user.created_at).format('DD-MMM, YYYY HH:MM') ? 1 : -1
	);

	return (
		<>
			<div className="dashboard-top-btn">
				<div className='back-redirect'>
					<button className="btn btn-default bg-dark filter-btn text-light" onClick={() => navigate(-1)}>Back</button>
				</div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
				</div>

			</div>
			<div className="table-sec common-table margin-t30 ">
				<div className="table-responsive">
					<table className="table white-td-table">
						<thead>
							<tr>
								<th>User</th>
								<th>Age</th>
								<th>Location</th>
								<th>Register Date</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{
								dataLoading === true ? <tr><td colSpan="6" className="text-center">
									<Spinner />
								</td></tr> :
									sorting.length ?
										sorting.map((item, index) => (
											<tr key={index}>
												<td>{item.user?.fullname || item.user?.username}</td>
												<td>{item.user.DateOfBirth ? moment().diff(item.user.DateOfBirth, 'years') : ''}</td>
												<td>{item.user?.currentCity || ''}</td>
												<td>{moment(item.user.created_at).format('DD-MMM, YYYY HH:MM')}</td>
												<td className="table-action">
													{/* <button onClick={() => handleShow(item.user)} className="btn btn-danger btn-suspend user-suspend">Suspend</button> */}
													<Link to={`/user-management/view/${item.user.id}`}><button className="btn btn-Primary">View</button></Link>
												</td>
											</tr>
										)).sort((a, b) => b.created_at - a.created_at) : <tr><td colSpan="6" className="text-center">No data found</td></tr>
							}
						</tbody>
					</table>
				</div>
			</div >
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input
											value={appliedFilters.search}
											onChange={(e) => setAppliedFilters({ ...appliedFilters, search: e.target.value })}
											type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input value={appliedFilters.start_date}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, start_date: e.target.value })}
												type="date" className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input value={appliedFilters.end_date}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, end_date: e.target.value })}
												type="date" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark full-width-btn add-couple" onClick={() => handleSerach({
									filters: appliedFilters
								})}>Apply Filter</button>
								<button className="btn btn-dark full-width-btn add-couple mt-3" onClick={resetFilters}>
									Reset Filters
								</button>
							</div>
						</div>
					</div>
				</> : <>
					<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<div className="custom-pop-body">
										<h4 className="pop-title text-center">Suspend User</h4>
										<p className='text-center'>Are you sure you want to suspend this user account?</p>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="button" onClick={suspendUser} disabled={modalData.loading} className="btn btn-dark btn-long">{
												modalData.loading ? <Spinner /> : 'Yes'
											}</button>
											<button type="button" onClick={handleClose} className="btn btn-long">No</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
					{
						lastPage > 1 ?
							<>

								{<ul className="pagination justify-content-center">
									<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
										<button className="page-link" onClick={previousPage}>
											Previous
										</button>
									</li>
									{Array.from({ length: lastPage }).map((_, index) => (
										<li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
											<button className="page-link" onClick={() => updateData(index + 1)}>
												{index + 1}
											</button>
										</li>
									))}
									<li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
										<button className="page-link" onClick={nextPage}>
											Next
										</button>
									</li>
								</ul>
								}
							</> : <></>
					}

				</>
			}
		</>
	);
};

export default UserManagementActive;