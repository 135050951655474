import React, { useState, useRef, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import coupon from "../assets/images/coupon-m.svg";
import couponnot from "../assets/images/coupon-m-svg.svg"
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../assets/images/logo.png';

const LeftNav = ({ toggle, handleClick }) => {

	// const [isMenuSubMenu, setMenuSubMenu] = useState(false);
	const ref = useRef()
	const ref1 = useRef()
	const ref2 = useRef()
	const ref3 = useRef()
	const ref4 = useRef()
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [isMenuOpens, setIsMenuOpens] = useState(false)
	const [isMenuOpens1, setIsMenuOpens1] = useState(false)
	const [isMenuOpenssub, setIsMenuOpenssub] = useState(false)
	const [isMenuOpenssub1, setIsMenuOpenssub1] = useState(false)
	const [isMenuSubMenu2, setMenuSubMenu2] = useState(false);
	const [isMenuSubMenu3, setMenuSubMenu3] = useState(false);
	const [isMenuSubMenu4, setMenuSubMenu4] = useState(false);
	const [isMenuSubMenu5, setMenuSubMenu5] = useState(false);

	// const toggleSubmenu = () => {
	// 	setMenuSubMenu(isMenuSubMenu === false ? true : false);
	// };
	// const toggleSubmenu2 = () => {
	// 	setMenuSubMenu2(isMenuSubMenu2 === false ? true : false);
	// };
	// const toggleSubmenu3 = () => {
	// 	setMenuSubMenu3(isMenuSubMenu3 === false ? true : false);
	// };
	const toggleSubmenu4 = () => {
		setMenuSubMenu4(!isMenuSubMenu4);
	};

	const toggleSubmenu5 = () => {
		setMenuSubMenu4(!isMenuSubMenu5);
	};

	// let boxClassSubMenu = ["sub-menu"];
	// let boxClassSubMenu2 = ["sub-menu"];
	// let boxClassSubMenu3 = ["sub-menu"];
	let boxClassSubMenu4 = ["sub-menu"];
	let boxClassSubMenu5 = ["sub-menu"];
	// if (isMenuSubMenu) {
	// 	boxClassSubMenu.push('show');
	// } else {
	// 	boxClassSubMenu.push('');
	// }
	// if (isMenuSubMenu2) {
	// 	boxClassSubMenu2.push('show');
	// } else {
	// 	boxClassSubMenu2.push('');
	// }
	// if (isMenuSubMenu3) {
	// 	boxClassSubMenu3.push('show');
	// } else {
	// 	boxClassSubMenu3.push('');
	// }
	if (isMenuSubMenu4) {
		boxClassSubMenu4.push('show');
	}
	else {
		boxClassSubMenu4.push('');
	}
	if (isMenuSubMenu5) {
		boxClassSubMenu5.push('show');
	}

	useEffect(() => {
		const checkIfClickedOutside = e => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
				setIsMenuOpen(false)
			}
		}

		document.addEventListener("mousedown", checkIfClickedOutside)

		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside)
		}
	}, [isMenuOpen])


	useEffect(() => {
		const checkIfClickedOutsides = e => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (isMenuOpens && ref1.current && !ref1.current.contains(e.target)) {
				setIsMenuOpens(false)
			}
		}

		document.addEventListener("mousedown", checkIfClickedOutsides)

		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutsides)
		}
	}, [isMenuOpens])

	useEffect(() => {
		const checkIfClickedOutsides1 = e => {

			if (isMenuOpens1 && ref2.current && !ref2.current.contains(e.target)) {
				setIsMenuOpens1(false)
			}
		}

		document.addEventListener("mousedown", checkIfClickedOutsides1)

		return () => {

			document.removeEventListener("mousedown", checkIfClickedOutsides1)
		}
	}, [isMenuOpens1])


	useEffect(() => {
		const checkIfClickedOutsidessub = e => {

			if (isMenuOpenssub && ref3.current && !ref3.current.contains(e.target)) {
				setIsMenuOpenssub(false)
			}
		}

		document.addEventListener("mousedown", checkIfClickedOutsidessub)

		return () => {

			document.removeEventListener("mousedown", checkIfClickedOutsidessub)
		}
	}, [isMenuOpenssub])


	useEffect(() => {
		const checkIfClickedOutsidessub1 = e => {

			if (isMenuOpenssub1 && ref4.current && !ref4.current.contains(e.target)) {
				setIsMenuOpenssub1(false)
			}
		}

		document.addEventListener("mousedown", checkIfClickedOutsidessub1)

		return () => {

			document.removeEventListener("mousedown", checkIfClickedOutsidessub1)
		}
	}, [isMenuOpenssub1])

	return (
		<div className={`side-nav ${toggle ? 'collapsed' : ''}`}>
			<div className="dashboard-logo">
				<Link to='/dashboard'>
					<img src={logo} alt="Whatson" className="large-logo" />
					<img alt="" src={logo} className="small-logo" />
				</Link>
			</div>
			<div className="navigation-bar">
				{/* <h4><span>Main</span> Menu</h4> */}
				<div className="toggle-icon" onClick={handleClick}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
				<ul>
					<li><NavLink to="/dashboard" exact="true" activeclassname="active"><span className="menu-icon dashboard-icon"></span><span className="menu-title">Dashboard</span></NavLink></li>

					<li className="has-sub side-nav-li" >
						{/* ref={ref} */}
						<Link className="dropdown-toggle" to="#" type="button" id="dropdownMenuClickable" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false" onClick={() => setIsMenuOpen(oldState => !oldState)}><span className="menu-icon management-icon"></span><span className="menu-title">Restaurant Management</span><span className="open-menu"><FontAwesomeIcon icon={faAngleDown} /></span></Link>
						{isMenuOpen && (
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuClickable">

								<li><NavLink exact="true" activeclassname="active" to="/accounts/active-restaurants">Active Account</NavLink></li>
								<li><NavLink exact="true" activeclassname="active" to="/accounts/pending-restaurants">Verification Pending</NavLink></li>
								<li><NavLink exact="true" activeclassname="active" to="/accounts/suspended-restaurants">Suspended Account</NavLink></li>
								<li><NavLink exact="true" activeclassname="active" to="/accounts/Payment-pending-restaurants">Pending Payment</NavLink></li>
								<li><NavLink exact="true" activeclassname="active" to="/accounts/All-restaurants">All Accounts</NavLink></li>
							</ul>
						)}
					</li>
					<li className="has-sub" onClick={toggleSubmenu4}>
						<Link to="/user-management/active"><span className="menu-icon users-icon"></span><span className="menu-title"> User Management</span><span className="open-menu"><FontAwesomeIcon icon={faAngleDown} /></span></Link>
						<ul className={boxClassSubMenu4.join(' ')} >
							<li><NavLink exact="true" activeclassname="active" to="/user-management/active">Active </NavLink></li>
							{/* <li><NavLink exact="true" activeclassname="active" to="/user-management/suspend">Suspend </NavLink></li> */}
						</ul>
					</li>


					<li><NavLink exact="true" activeclassname="active" to="/admin-membership"><span className="menu-icon membersip-icon"></span><span className="menu-title">Membership</span></NavLink></li>
					<li className='couspon'><NavLink exact="true" activeclassname="active " to="/promotions">  <img className='show-ico' src={coupon} width="15%" />
						<img className='not-show' src={couponnot} width="15%" />
						PromoCode</NavLink></li>
					<li className="has-sub side-nav-li" ref1={ref1} >
						<Link className="dropdown-toggle" to="#" type="button" id="dropdownMenuClickable" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false" onClick={() => setIsMenuOpens(oldState => !oldState)}><span className="menu-icon communication-icon"></span><span className="menu-title">Restaurant Communication</span><span className="open-menu"><FontAwesomeIcon icon={faAngleDown} /></span></Link>
						{isMenuOpens && (
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuClickable">
								<li><NavLink exact="true" activeclassname="active" to="/restaurant-communication/general">General Message</NavLink></li>
								<li><NavLink exact="true" activeclassname="active" to="/restaurant-communication/Request">Request</NavLink></li>

							</ul>
						)}
					</li>
					<li className="has-sub mastery mastry-li side-nav-li" ref2={ref2}>
						<Link className="dropdown-toggle" to="#" type="button" id="dropdownMenuClickable" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false" onClick={() => setIsMenuOpens1(oldState => !oldState)}><span className="menu-icon master-icon"></span><span className="menu-title">Master Entry</span><span className="open-menu"><FontAwesomeIcon icon={faAngleDown} /></span></Link>
						{isMenuOpens1 && (
							<ul class="dropdown-menu side-nav-li" aria-labelledby="dropdownMenuClickable" ref3={ref3}>
								<li><NavLink exact="true" activeclassname="active" to="/master-entry/food">Food</NavLink></li>
								<li className="dropdown-toggle d-flex pe-3" id="dropdownMenuClickable" type="button" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false" onClick={() => setIsMenuOpenssub(oldState => !oldState)}><NavLink exact="true" activeclassname="active" to="/master-entry/drinks">Drink</NavLink></li>
								{isMenuOpenssub && (
									<ul class="dropdown-menu" aria-labelledby="dropdownMenuClickable">
										<li><NavLink exact="true" activeclassname="active" to="/master-entry/drinks">Wine</NavLink></li>
										<li><NavLink exact="true" activeclassname="active" to="/master-entry/wine-type">Wine Types</NavLink></li>

										<li><NavLink exact="true" activeclassname="active" to="/master-entry/beer">Beer</NavLink></li>
										<li><NavLink exact="true" activeclassname="active" to="/master-entry/cider">Cider</NavLink></li>
										<li><NavLink exact="true" activeclassname="active" to="/master-entry/other">Other</NavLink></li>
										<li><NavLink exact="true" activeclassname="active" to="/master-entry/region">Region</NavLink></li>
										<li><NavLink exact="true" activeclassname="active" to="/master-entry/container">Container</NavLink></li>
										<li><NavLink exact="true" activeclassname="active" to="/master-entry/size">Size</NavLink></li>
									</ul>
								)}
								<li ref4={ref4} className="dropdown-toggle d-flex pe-3" type="button" id="dropdownMenuClickable" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false" onClick={() => setIsMenuOpenssub1(oldState => !oldState)}><NavLink exact="true" activeclassname="active" to="/master-entry/games">Games</NavLink></li>
								{isMenuOpenssub1 && (
									<ul class="dropdown-menu" aria-labelledby="dropdownMenuClickable">
										<li><NavLink exact="true" activeclassname="active" to="/master-entry/games">Sports</NavLink></li>
										<li><NavLink exact="true" activeclassname="active" to="/master-entry/team">Team</NavLink></li>
									</ul>
								)}
								{/*<li><NavLink exact="true" activeclassname="active" to="/happy-hour/drink">Drinks</NavLink></li>*/}
								{/*<li><NavLink exact="true" activeclassname="active" to="/happy-hour/drink">Game</NavLink></li>*/}
								<li><NavLink exact="true" activeclassname="active" to="/master-entry/tags">Tags</NavLink></li>
							</ul>
						)}
					</li>
				</ul>
			</div>

		</div>
	)
}

export { LeftNav };