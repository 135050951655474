import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';

import Filter from '../../assets/images/filter-ico.svg';
import apiService from '../../core/apiService';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { REACT_APP_LIMIT } from '../../apiServices/apiServices';


const RestaurantCommunicationGeneral = () => {
	const [isFilter, setisFilter] = useState(false);
	const [restaurantdata, setRestaurantData] = useState(null)
	const [restaurant, setRestaurant] = useState(null);

	const [appliedFilters, setAppliedFilters] = useState({
		search: '',
		start_date: '',
		end_date: '',

	})
	const navigate = useNavigate()

	const [refresh, setRefresh] = useState(false)
	const [reply, setReply] = useState('')
	const [selectedRow, setSelectedRow] = useState(false);
	const [Approveshow, ApprovesetShow] = useState(false);
	const [currentPage, setCurrentPage] = useState(1)

	const [Denyshow, DenysetShow] = useState(false);
	const [total, setTotal] = useState([])
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);

		if (isFilter === true) return
		setCurrentPage(1)
	}
	const updateData = (page) => {
		setCurrentPage(page)
	}
	const handlesearch = (args) => {
		let params = {
			pageno: currentPage,
			search: appliedFilters.search,
			start_date: appliedFilters.start_date,
			end_date: appliedFilters.end_date
		}
		if (args?.filters) {
			params = { ...params, ...args.filters };
		}
	   setRestaurantData(null)
		apiService.get('/getCommunication', params, ({ err, data }) => {
			if (err) return console.log(err);
			console.log('getcommunication', data.data)
			setRestaurant(data.data.rows)
			setRestaurantData(data.data.rows);
			setTotal(data.data.count)
			setisFilter(false)
		});
	}
	const resetFilters = () => {
		setAppliedFilters({
			search: '',
			start_date: '',
			end_date: '',
		});
		handlesearch({
			filters: {
				search: '',
				start_date: '',
				end_date: '',
			},
		});
	}


	useEffect(() => {
		handlesearch({
			filters: appliedFilters
		})
	}, [refresh, currentPage])

	const handleApproveClose = () => { ApprovesetShow(false); setSelectedRow(); setReply(''); }
	const handleApprovedShow = (data) => { ApprovesetShow(true); setSelectedRow(data) }

	const handleDenyClose = () => { DenysetShow(false); setSelectedRow(); setReply('') }
	const handleDenydShow = (data) => { DenysetShow(true); setSelectedRow(data) }
	const handleEvent = (id, status, reply) => {
		apiService.post('/postRequest', { id, status, message: reply }, ({ err, data }) => {
			if (err) return console.log(err);
			if (status == 1) { handleApproveClose() }
			else { handleDenyClose() }
			setRefresh(!refresh)
		});
	}
	const lastPage = Math.ceil(total / REACT_APP_LIMIT)
	console.log(lastPage, "lastpage")
	const nextPage = () => {
		if (currentPage < lastPage) {
			updateData(currentPage + 1)
		}
	}
	const previousPage = () => {
		updateData(currentPage - 1)
	}




	return (
		<>
			<div className="dashboard-top-btn">
				<div className='back-redirect'>
					<button className="btn btn-default bg-dark filter-btn text-light" onClick={() => navigate(-1)}>Back</button>
				</div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
				</div>
			</div>
			<div className="table-sec common-table margin-t30 ">
				<div className="table-responsive">
					<table className="table white-td-table">
						<thead>
							<tr>
								<th>Restaurant Name</th>
								<th>Type</th>
								<th>Subject</th>
								<th>Description</th>
											
								<th>Date Description Time</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<>
								{
									restaurantdata == null ? <tr><td colSpan="6" className="text-center">
										<Spinner />
									</td></tr> :
										<>
											{restaurantdata?.length ? <>
												{restaurantdata?.map(item =>
													{
														console.log(item.status)
														return  <tr>
														<td>{item?.restaurant?.restaurant_name}</td>
														<td>{item?.type?.type_name}</td>
														<td>{item?.subject}</td>
														<td>{item?.description}</td>
														<td>{
															moment(item?.updated_at).format('DD-MMM, YYYY HH:MM')}</td>
														<td className="table-action">
															{item.status? <>
																{
																	(item.status == 1) ? <button className="btn btn-success" disabled>Replied</button> : <button className="btn btn-danger" disabled >Rejected</button>
																}
															</> :
																<><button className="btn btn-success" onClick={() => handleApprovedShow(item)} >Reply</button>
																	{/* <button className="btn btn-danger" onClick={() => handleDenydShow(item)} >Deny</button>  */}
																	 </>
															}
	
														</td>
													</tr>
													})}
											</> :
												<tr><td colSpan="6" className="text-center">
													No Data Found
												</td></tr>}
										</>
								}

							</>

						</tbody>
					</table>
				</div>
			</div>
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input
											value={appliedFilters.search}
											onChange={(e) => setAppliedFilters({ ...appliedFilters, search: e.target.value })}
											type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input value={appliedFilters.start_date}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, start_date: e.target.value })}
												type="date" className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input value={appliedFilters.end_date}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, end_date: e.target.value })}
												type="date" className="form-control" placeholder="End Date" />
										</div>

									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark full-width-btn add-couple" onClick={() => handlesearch({
									filters: appliedFilters
								})}>Apply Filter</button>
								<button className="btn btn-dark full-width-btn add-couple mt-3" onClick={resetFilters}>
									Reset Filters
								</button>
							</div>
						</div>
					</div>
				</> : <>
					<Modal show={Approveshow} className="common-pop popup-medium  Approved-popup-box" onHide={handleApproveClose}>
						<button type="button" className="btn-close" onClick={handleApproveClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box Approved-popup">
								<div id="add-user-form">
									<div id="merchant-faq-us">
										<div className="custom-pop-body">
											<h4>{selectedRow?.restaurant?.restaurant_name}</h4>
											<div className="pop-form">
												<div className="row">
													<div className="col-sm-6">
														<label >Type: {selectedRow?.type?.type_name} </label>

													</div>
													<div className="col-sm-6">

														<label>Subject: {selectedRow?.subject} </label>

													</div>


												</div>
												<label>Description:{selectedRow?.description} </label>
												<div className="mb-4">
													<label className="form-label">Reply </label>
													<input className="form-control" value={reply} onChange={(e) => setReply(e.target.value)} />
												</div>
											</div>
										</div>


									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={() => { handleEvent(selectedRow.id, "1", reply) }} className="btn btn-dark green btn-long">Reply</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>

					<Modal show={Denyshow} className="common-pop popup-medium Deny-popup-box" onHide={handleDenyClose}>
						<button type="button" className="btn-close" onClick={handleDenyClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box Deny-popup">
								<div id="add-user-form">
									<div id="merchant-faq-us">
										<div className="custom-pop-body">
											<h4>{selectedRow?.restaurant?.restaurant_name}</h4>
											<div className="pop-form">
												<div className="row">
													<div className="col-sm-6">
														<label >Type: {selectedRow?.type?.type_name} </label>

													</div>
													<div className="col-sm-6">

														<label>Subject: {selectedRow?.subject} </label>

													</div>


												</div>
												<label>Description:{selectedRow?.description} </label>
												<div className="mb-4">
													<label className="form-label">Reply </label>
													<input className="form-control" value={reply} onChange={(e) => setReply(e.target.value)} />
												</div>
											</div>
										</div>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={() => { handleEvent(selectedRow.id, "2", reply); }} className="btn btn-dark green btn-long">Deny</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
					{
						lastPage > 1 ?
							<>

								{<ul className="pagination justify-content-center">
									<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
										<button className="page-link" onClick={previousPage}>
											Previous
										</button>
									</li>
									{Array.from({ length: lastPage }).map((_, index) => (
										<li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
											<button className="page-link" onClick={() => updateData(index + 1)}>
												{index + 1}
											</button>
										</li>
									))}
									<li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
										<button className="page-link" onClick={nextPage}>
											Next
										</button>
									</li>
								</ul>
								}
							</> : <></>
					}

				</>}
		</>
	);
};

export default RestaurantCommunicationGeneral;