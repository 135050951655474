import React, {useState } from 'react';
import {  useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../core/apiService';

const AdminMembershipUpdate = () => {
	const location = useLocation();
	const planDetails = location.state.features
	const type = location.state.type
	const [monthlyPrice, setMonthlyPrice] = useState(planDetails.subscription_price);
	const [subscription, setSubscription] = useState(location.state.features.subscriptionfeatures)
	const navigate = useNavigate()

		const updateState = (data,id,value) => {
			const newState = data.map(obj => {
			  // 👇️ if id equals 2, update country property
			  if (obj.id === id) {
				return {...obj, enable: value};
			  }
			  // 👇️ otherwise return the object as is
			  return obj;
			});
			setSubscription(newState);
		  };
		  
		  console.log(monthlyPrice,"mothlyprice")
		  const handleCreate=(inputValue)=>{
			apiService.post(`/update/${planDetails.id}`, { "fetureid":inputValue,subscription_price:monthlyPrice}, ({ err, data }) => {
				console.log(data.data,"data.data")
				if (err) return console.log(err);
			});
		}
	return (
		<div>
				<div id="Premimum-Membership">
					
					<div className="container">
						
						<div className="row">
						
							<div className="col-md-8 premiumship">
							<h4>{planDetails.subscription_name} </h4>
								<ul className="plan-point ">
										{
                                    subscription?.map(item=>
										{
                                          return <li><input type='checkbox' value={item.feature_id}  checked={item.enable}  
										  onChange={(e)=>updateState(subscription,item.id,(item.enable === 1)?0:1 )}/> {item.feature.feature_name}</li>
										}
									 )
									}
									</ul>

							</div>
							<div className="col-md-4">
							</div>
						</div>
						<div className="row">

							<div className="col-md-9 ">
								<div className="row">
									<div className="col-sm-5 update-form">
									
											<label>{type} Price</label>
											<input type="email" className="form-control"   value={monthlyPrice} onChange={(e=>{setMonthlyPrice(e.target.value)})}/>
											<span className="error email-error">Email is required*</span>
									
									</div>
									{/* <div className="col-sm-5 update-form">
											<label>Yearly Price</label>
											<input type="password" className="form-control" />
											<span className="error password-error">Password is required*</span>
									</div> */}
								</div>
							</div>
							<div className="col-md-4">
							</div>
							<div className="create-from-btn">
								<button className="btn btn-transparent updatemember" onClick={()=>navigate(-1)}>Back</button>
								<button className="btn btn-dark updatemember" onClick={()=>handleCreate(subscription)}>Update Membership</button>
							</div>



						</div>

					
					</div>
			</div>

			
		</div>
	);
};

export default AdminMembershipUpdate;
