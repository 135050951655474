import React, { useEffect, useState } from 'react';
import apiService from '../../core/apiService';

import { Spinner } from 'react-bootstrap';
import King from '../../assets/images/king.png';

import total from '../../assets/images/total-request.png';
import pending from '../../assets/images/pending-request.png';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
const Dashboard = () => {
    const [vendorReq, setVendorReq] = useState('')
    const [pendinreq, setPending] = useState('')
    const [dataLoading, setDataLoading] = useState(true);
    const [dataRegion, setuserRegion] = useState(null);
    const [ResRegion, setResRegion] = useState(null)
    const [trending, setTrending] = useState(null)
    const [active, setActive] = useState(null)



    console.log(pendinreq, "pendinreq")
    const navigate = useNavigate();

    const handleImageClick = () => {
        // Navigate to the '/restaurant-communication/request' route when the image is clicked
        navigate('/restaurant-communication/request');
    };

    useEffect(() => {
        apiService.get('/getTrendings', {}, ({ err, data }) => {
            if (err) return console.log(err);
            // console.log(data.data.pendingVerificationResquest, "ghghs")
            const count = data.data.pendingVerificationResquest

        

            setVendorReq(data.data.RequestfromVendors)
            setPending(count.length)
            setuserRegion(data.data.TotalUsersByRegion.splice(0, 3))
            setResRegion(data.data.TotalRestaurantsByRegion.splice(0, 3))
            setActive(data.data.ActiveUsers.splice(0, 3))
            setDataLoading(false)

        });






        apiService.get('/getTrending', {}, ({ err, data }) => {
            if (err) return console.log(err);
            console.log(data.data, "ghghs")
            let premium = [], basic = [];
            if (data.data.length) {
                data.data.map(item => {
                    if (item.restaurantsubscription.susbcription_id === 2 || item.restaurantsubscription.susbcription_id === 4) {
                        premium.push(item)
                    } else {
                        basic.push(item)
                    }
                })
                console.log(premium.length, "dsjahdjhsajdhhk")
                setTrending([...premium.splice(0, 3), ...basic.splice(0, 1)])
            }

            setDataLoading(false)

        });


    }, []);


    return (
        <>
            <div className="dashboard-top-btn">
            </div>
            <div className="row">
                <div className="col-sm-8">
                    <div className="dashboard-auto-wrapper mb-4 Dashboard-table-first">
                        <div className="wrapper-head">
                            <h3>Trending Restaurant</h3>
                        </div>
                        <div className="common-table">
                            <div className="table-responsive">
                                <table className="table table-borderless first-table main-div-tab">
                                    <thead>
                                        <tr>
                                            <th> Restaurant Name/Email</th>
                                            <th>Contact No</th>
                                            <th>Whatson_id</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            dataLoading === true ? <tr><td colSpan="6" className="text-center">
                                                <Spinner />
                                            </td></tr> :
                                            
                                                trending?.length ?
                                                    trending.map((item, index) => (

                                                        <tr>
                                                            {item.restaurantsubscription.susbcription_id === 2 || item.restaurantsubscription.susbcription_id === 4 ?
                                                                <td className="table-user-king-icon">{item.restaurant_name}<div className='premium-item'><img alt="" src={King} /></div>
                                                                    <span>{item.restaurant_email}</span>
                                                                </td> : <td >{item.restaurant_name}
                                                                    <span>{item.restaurant_email}</span>
                                                                </td>}

                                                            <td>{item.restaurant_phone_number}</td>
                                                            <td>{item.id}</td>

                                                        </tr>
                                                    )) :
                                                    <tr><td colSpan="6" className="text-center">
                                                        <p>No user Found</p>
                                                    </td></tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="dashboard-auto-wrapper user-region">
                                <div className="wrapper-head">
                                    <h3>Total user by Region</h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-hover dashboard-table">
                                        <thead>
                                            <tr>
                                                <th>Region</th>
                                                <th> Total Users</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataRegion === null ? <tr><td colSpan="6" className="text-center">
                                                    <Spinner />
                                                </td></tr> :
                                                    dataRegion.length ?
                                                        dataRegion.map((item, index) => (

                                                            <tr>
                                                                <td >

                                                                    <span>{item.currentCity}</span>
                                                                </td>
                                                                <td>{item.userCount}</td>
                                                            </tr>

                                                        )) :
                                                        dataRegion.length ?
                                                            dataRegion.map((item, index) => (

                                                                <tr>
                                                                    <td >

                                                                        <span>{item.currentCity}</span>
                                                                    </td>
                                                                    <td>{item.userCount}</td>
                                                                </tr>

                                                            )) :

                                                            <tr><td colSpan="6" className="text-center">
                                                                <p>No user Found</p>
                                                            </td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="dashboard-auto-wrapper restaurant-region ">
                                <div className="wrapper-head">
                                    <h3>Restaurant by Region </h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-hover dashboard-table">
                                        <thead>
                                            <tr>
                                                <th>Region</th>
                                                <th> Restaurants</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ResRegion === null ? <tr><td colSpan="6" className="text-center">
                                                    <Spinner />
                                                </td></tr> :
                                                    ResRegion.length ?
                                                        ResRegion.map((item, index) => (

                                                            <tr>
                                                                <td >

                                                                    <span>{item.city}</span>
                                                                </td>
                                                                <td>{item.count}</td>
                                                              


                                                            </tr>
                                                        )) :
                                                        <tr><td colSpan="6" className="text-center">
                                                            <p>No Rest Found</p>
                                                        </td></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="dashboard-auto-wrapper Dashboard-table-second">
                        <div className="wrapper-head">
                            <h3>Active Users</h3>

                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover dashboard-table">
                                <thead>
                                    <tr>
                                        <th> Location</th>
                                        <th> Users </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        active === null ? <tr><td colSpan="6" className="text-center">
                                            <Spinner />
                                        </td></tr> :
                                            // active.length ?
                                            //     active.map((item, index) => (

                                            //         <tr>
                                            //             <td >

                                            //                 <span>{item.currentCity}</span>
                                            //             </td>
                                            //             <td>{item.userCount}</td>
                                            //         </tr>
                                                    
                                            //     )) :
                                                active.length ?
                                                active.map((item, index) => (

                                                    <tr>
                                                        <td >

                                                            <span>{item.currentCity}</span>
                                                        </td>
                                                        <td>{item.userCount}</td>
                                                    </tr>
                                                    
                                                )) :
                                                <tr><td colSpan="6" className="text-center">
                                                    <p>No user Found</p>
                                                </td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="dashboard-auto-wrapper margin-t30 requests" >
                        <div className="wrapper-head">
                            <h3 className="text-center">Requests from Vendors</h3>
                        </div>
                        <div className="follower-count-box">
                            <Link onClick='' to='/restaurant-communication/request'>
                                <span className="follower-icon" ><img alt="" src={total} /></span>
                                {/* style={{cursor:'pointer'}} onClick={handleImageClick} */}
                                <h4>{vendorReq}</h4>
                                <p>Total Requests</p>
                            </Link>

                        </div>
                    </div>
                    <div className="dashboard-auto-wrapper margin-t30 pending-requests">
                        <div className="wrapper-head">
                            <h3 className="text-center">Pending Verification Requests</h3>
                        </div>
                        <div className="follower-count-box">
                            <span className="follower-icon"><img alt="" src={pending} /></span>
                            <h4>{pendinreq}</h4>

                            <p>Pending Requests</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
export default Dashboard;