import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import apiService from '../../core/apiService';
import { Spinner } from 'react-bootstrap';

const GameSpecialFoodListing = ({
	restaurant_id
}) => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};

	const [dataLoading, setDataLoading] = useState(true);
	const [foodsData, setFoodsData] = useState(null);

	useEffect(() => {
		// apiService.get('/restaurants/specials', { special_id: 2, restaurant_id, type_id: 1 }, ({ err, data }) => {
		// 	if (err) return setDataLoading(false), console.log(err);
		// 	setFoodsData(data.data);
		// 	setDataLoading(false);
		// });
		apiService.get(`/restaurants/specials`, { special_id: 2, restaurant_id, type_id: 1 }, async ({ err, data }) => {
			if (err) return setDataLoading(false), setisFilter(false);

			console.log(data,'datdatdtdtadtdtdt')
			let temp = data
			await apiService.get(`/get-foodcategory`, {}, ({ err, data }) => {
				if (err) return console.log(err);
				let temp1 = data
				apiService.get(`/listallTags`, {}, ({ err, data }) => {

					if (err) return console.log(err);


					let filteredFoodCategories = []
					let filterTags = []
					console.log(temp.data,'dtadtdta')
					temp.data.map(item => {
						console.log(item,'item.restauranttag')
						if (item.restauranttag?.foodcategory_ids == null) return
						let foodId = item.restauranttag?.foodcategory_ids
						console.log(foodId, 'foods')
						filteredFoodCategories = temp1.data.filter(food => eval(foodId).includes(food.id))
							.map(filteredFood => filteredFood.foodcategory_name);

							let tagId = item.restauranttag?.tags_ids
							filterTags = data.data.filter(tag => eval(tagId).includes(tag.id))
							.map(filteredTag => filteredTag.tag_name);

						item.Standard_Tags = filteredFoodCategories.join(',')
                        item.Special_tag = filterTags.join(',')
						console.log(filterTags, 'filetrs')

					});
					setFoodsData(temp.data);
				})

			});



			// setTotal(data.data.count)
			setDataLoading(false);
			setisFilter(false);

		})
	}, []);


	return (
		<>
			<div className="table-sec common-table margin-t30">
				<div className="table-responsive">
					<table className="table white-td-table">
						<thead>
							<tr>
								<th>Sport</th>
								<th>Team</th>
								<th>Food Category</th>
								<th>Discount Type</th>
								<th>Price</th>
								<th>Item Description</th>
								<th>Special Tags</th>
								<th>Standard Tags</th>

							</tr>
						</thead>
						<tbody className={foodsData?.length > 0 ? '' : 'empty_row'}>
							{
								foodsData == null ?
									<tr>
										<td colSpan={10}>
											<div className='table-inner-loader'>
												<Spinner />
											</div>
										</td>
									</tr> :
									foodsData?.length ?
										foodsData?.map((item, i) =>
										(
											<tr className='w-100'>
												<td>{item.sport?.sports_name}</td>
												<td>{item.sportsteam?.team_name}</td>
												<td>{item.foodcategory?.foodcategory_name || "Multiple Items"}</td>
												<td className='discount_type'>{item.discount_type}</td>
												<td>${item.discount_amount}</td>
												<td >{item.item_description}</td>
												<td>{item.Special_tag}</td>
												<td>{item.Standard_Tags}</td>
											</tr>
										)) :
										<tr>
											<td colspan="10">
												No records found!
											</td>
										</tr>
							}

						</tbody>
					</table>
				</div>
			</div>
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark full-width-btn add-couple" onClick={showFilter}>Apply Filter</button>
							</div>
						</div>
					</div>
				</> : <></>
			}
		</>
	);
};

export default GameSpecialFoodListing;