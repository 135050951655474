import React from 'react';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPlus } from "@fortawesome/free-solid-svg-icons";



const BusinessVerify = () => {
    const navigate = useNavigate();
const location = useLocation();
const fromPage = location.state?.from?.pathname || '/verification';

const handleSubmit = (event) => {
    event.preventDefault();
    navigate(fromPage, {replace: true})
}
    
    return (
        <div>
            <div id="register-page">
		<div className="login-form">
			<div className="login-form-inner">
				<div className="login-logo">
					<img src="/assets/images/logo.png" alt="Whatson"/>
					<p>Alternate Verification Process of your Business</p>
				</div>
				<div className="login-form-box">
					<form onSubmit={handleSubmit}>
						<div className="mb-4">
							<label>Restaurant name</label>
							<input type="text" id="username" className="form-control"/>
						</div>
						<div className="mb-4">
							<label>Address</label>
							<input type="text" id="address" className="form-control"/>
							<span className="error email-error">Address is required*</span>
						</div>
						<div className="mb-4">
							<label>Upload Document</label>
							<div className="restaurant-photo-box">
								<div className="restaurant-photo-input">
								<FontAwesomeIcon icon={faPlus} />
								</div>
								<input type="file" className="photo-input"/>
							</div>
						</div>
						<div className="mb-3">
							<button className="btn btn-dark full-btn btn-lg signin-btn">Next</button>
						</div>
					</form>
				</div>
				<div className="form-bottom-link">If you are not registered with Google My Business Please <a href="https://business.google.com/create/new" target="_blank"><b> Click here!</b></a> </div>
			</div>
		</div>
		<div className="login-img">
			<div className="login-img-inner">
				<div className="dashboard-title">
					<h3>WELCOME TO</h3>
					<h1>Restaurant Panel</h1>
					<p>Register to Access Dashboard</p>
				</div>
			</div>
		</div>
	</div>
        </div>
    );
};

export default BusinessVerify;
